// API Configuration
export const API_BASE_URL = 'https://cleandata-api-25456108afa7.herokuapp.com';

// Socket.IO configuration
export const SOCKET_CONFIG = {
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    timeout: 10000
};

// Other configuration constants
export const DEFAULT_PAGINATION = {
    limit: 10,
    page: 1
};

export const REFRESH_INTERVALS = {
    stats: 60000, // 1 minute in production
    userList: 60000, // 1 minute in production
    scanStatus: 10000 // 10 seconds in production
}; 