import React, { useState, useEffect, useRef, useMemo, useCallback, memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSocket } from '../hooks/useSocket';
import api from '../services/apiService';
import socketService from '../services/socketService';
import LoadingSpinner from '../components/LoadingSpinner';
import UserInfo from '../components/UserInfo';
import DashboardStats from '../components/DashboardStats';
import FeatureToggles from '../components/FeatureToggles';
import DataBrokerListComponent from '../components/DataBrokerListComponent';
import DataPointsComponent from '../components/DataPointsComponent';
import ErrorFallback from '../components/ErrorFallback';
import ErrorBoundary from '../components/ErrorBoundary';
import { useAuth } from '../context/AuthContext';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import MobileNavBar from '../components/MobileNavbar';
import { formatDataBrokerList, getScreenshotUrl } from '../utils';
import './Dashboard.css';
import ImagePreview from '../components/ImagePreview';
import simulationService from '../services/simulationService';
import Typography from '@mui/material/Typography';
import { Alert } from '@mui/material';

const THUMB_IO_API_KEY = 'YOUR_THUMB_IO_API_KEY'; // Replace with your actual API key

// Constants
const TOTAL_SITES = 42; // Total number of data broker sites to scan

// Memoized components
const MemoizedUserInfo = memo(UserInfo, (prev, next) => {
    return prev.user?.id === next.user?.id && 
           prev.user?.memberSince === next.user?.memberSince &&
           prev.user?.subscriptionStatus === next.user?.subscriptionStatus;
});

const MemoizedDashboardStats = memo(DashboardStats, (prev, next) => {
    return JSON.stringify(prev.stats) === JSON.stringify(next.stats);
});

const MemoizedDataBrokerList = memo(DataBrokerListComponent, (prev, next) => {
    return JSON.stringify(prev.dataBrokers) === JSON.stringify(next.dataBrokers) &&
           prev.isScanning === next.isScanning &&
           prev.currentSite === next.currentSite &&
           prev.progress === next.progress &&
           prev.currentStageFromProps === next.currentStageFromProps &&
           prev.message === next.message &&
           prev.profilesFound === next.profilesFound &&
           prev.sitesScanned === next.sitesScanned &&
           prev.threatsFound === next.threatsFound &&
           prev.lastScanTime === next.lastScanTime &&
           prev.nextScanTime === next.nextScanTime;
});

const MemoizedFeatureToggles = memo(FeatureToggles);

const Dashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, setUser, refreshUserData } = useAuth();
    const { 
        connected,
        error: socketError, 
        isSimulating, 
        simulationProgress,
        currentSite,
        currentStage,
        statusMessage,
        metrics,
        emit,
        startSimulation,
        stopSimulation
    } = useSocket(user?.id);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
    const [error, setError] = useState(null);
    const [socketErrorState, setSocketError] = useState(null);
    const [currentScanStatus, setCurrentScanStatus] = useState('');
    const [scanProgress, setScanProgress] = useState(0);
    const [isInitialized, setIsInitialized] = useState(false);
    const [dataBrokerList, setDataBrokerList] = useState(initialDataBrokers);
    const [dashboardData, setDashboardData] = useState({
        stats: {
            isScanning: false,
            progress: 0,
            currentSite: null,
            currentStage: null,
            message: null,
            sitesScanned: 0,
            potentialThreats: 0,
            totalMatches: 0,
            lastScanTime: null,
            nextScanTime: null
        },
        featureToggles: {
            multi_factor_auth: false,
            role_based_access: false,
            monitoring_verification: false,
            data_leak_notification: false
        }
    });

    const dashboardRef = useRef(null);
    const socketRef = useRef(null);
    const retryCountRef = useRef(0);
    const maxRetries = 3;

    // Memoized live search items
    const liveSearchItems = useMemo(() => [
        { 
            url: `https://www.fastpeoplesearch.com/name/${user?.firstName}-${user?.lastName}`,
            siteName: 'FastPeopleSearch',
            status: 'Monitoring',
            isAnalyzing: true,
            previewUrl: `https://image.thum.io/get/width/600/crop/800/noanimate/auth/${THUMB_IO_API_KEY}/https://www.fastpeoplesearch.com/name/${user?.firstName}-${user?.lastName}`
        },
        { 
            url: `https://www.truepeoplesearch.com/results?name=${user?.firstName}%20${user?.lastName}`,
            siteName: 'TruePeopleSearch',
            status: 'Monitoring',
            isAnalyzing: true,
            previewUrl: `https://image.thum.io/get/width/600/crop/800/noanimate/auth/${THUMB_IO_API_KEY}/https://www.truepeoplesearch.com/results?name=${user?.firstName}%20${user?.lastName}`
        }
    ], [user?.firstName, user?.lastName]);

    // Fetch initial data
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                setIsLoadingInitialData(true);
                setError(null);

                // First check if we have a valid token
                const token = localStorage.getItem('token') || sessionStorage.getItem('token');
                if (!token) {
                    console.log('[Dashboard] No token found, redirecting to login...');
                    navigate('/login');
                    return;
                }

                // Then try to fetch user data
                if (!user) {
                    try {
                        await refreshUserData();
                    } catch (error) {
                        console.error('[Dashboard] Error refreshing user data:', error);
                        // Don't redirect immediately on refresh error
                        if (error.response?.status === 401) {
                            navigate('/login');
                            return;
                        }
                    }
                }

                // Only proceed with other data fetching if we have a user
                if (user?.id) {
                    try {
                        await Promise.all([
                            fetchStats().catch(err => {
                                console.error('[Dashboard] Error fetching stats:', err);
                                return null;
                            }),
                            fetchScanStats().catch(err => {
                                console.error('[Dashboard] Error fetching scan stats:', err);
                                return null;
                            })
                        ]);
                    } catch (error) {
                        console.error('[Dashboard] Error fetching dashboard data:', error);
                        // Don't set error for 404s - API endpoints might not be ready
                        if (error.response?.status !== 404) {
                            setError('Failed to load some dashboard data');
                        }
                    }
                }

                setIsLoadingInitialData(false);
            } catch (error) {
                console.error('[Dashboard] Critical error in fetchInitialData:', error);
                setError('Failed to load dashboard data');
                setIsLoadingInitialData(false);

                // Only redirect on auth errors
                if (error.response?.status === 401) {
                    navigate('/login');
                }
            }
        };

        fetchInitialData();
    }, [user, refreshUserData, navigate]);

    // Handle socket connection
    useEffect(() => {
        if (!user?.id || !connected) {
            console.log('[Dashboard] Waiting for socket connection:', {
                hasUser: !!user?.id,
                connected,
                timestamp: new Date().toISOString()
            });
            return;
        }

        console.log('[Dashboard] Socket connected:', {
            userId: user.id,
            timestamp: new Date().toISOString()
        });

    }, [user?.id, connected]);

    // Update dashboard data when socket events are received
    useEffect(() => {
        if (isSimulating) {
            setDashboardData(prev => ({
                ...prev,
                stats: {
                    ...prev.stats,
                    isScanning: true,
                    progress: simulationProgress,
                    currentSite,
                    currentStage,
                    message: statusMessage,
                    sitesScanned: metrics.sitesScanned || 0,
                    potentialThreats: metrics.potentialThreats || 0,
                    totalMatches: metrics.totalMatches || 0
                }
            }));
        } else if (simulationProgress === 100) {
            // Handle completion state
            const now = new Date();
            const nextScan = new Date(now.getTime() + (30 * 60 * 1000)); // Next scan in 30 minutes
            
            setDashboardData(prev => ({
                ...prev,
                stats: {
                    ...prev.stats,
                    isScanning: false,
                    progress: 100,
                    currentSite: null,
                    currentStage: null,
                    message: "Scan completed successfully",
                    sitesScanned: metrics.sitesScanned || prev.stats.sitesScanned,
                    potentialThreats: metrics.potentialThreats || prev.stats.potentialThreats,
                    totalMatches: metrics.totalMatches || prev.stats.totalMatches,
                    lastScanTime: now.toISOString(),
                    nextScanTime: nextScan.toISOString()
                }
            }));

            // Reset data broker list to completed state
            setDataBrokerList(prevList => 
                prevList.map(broker => ({
                    ...broker,
                    status: "Completed",
                    stage: null,
                    message: null
                }))
            );
        } else {
            // Handle idle state
            setDashboardData(prev => ({
                ...prev,
                stats: {
                    ...prev.stats,
                    isScanning: false,
                    progress: 0,
                    currentSite: null,
                    currentStage: null,
                    message: null
                }
            }));
        }
    }, [isSimulating, simulationProgress, currentSite, currentStage, statusMessage, metrics]);

    // Handle socket reconnection
    useEffect(() => {
        if (connected && !isSimulating && simulationProgress === 40) {
            // If we're connected but have a partial progress, check scan status
            emit('check_scan_status');
        }
    }, [connected, isSimulating, simulationProgress, emit]);

    // Connection status feedback with more detail
    const connectionStatusMessage = useMemo(() => {
        if (connected) {
            setSocketError(null);
            return { type: 'success', message: 'Connected to real-time updates' };
        } else if (socketError) {
            setSocketError('Unable to connect to the server. Please check your internet connection or try again later.');
            return { type: 'error', message: 'Error connecting to real-time updates - Please refresh the page' };
        }
        return null;
    }, [connected, socketError]);

    // Update the scroll effect
    useEffect(() => {
        if (user) {
            const timer = setTimeout(() => {
                const dashboardTitle = document.querySelector('.dashboard-content h1');
                if (dashboardTitle) {
                    window.scrollTo({
                        top: dashboardTitle.offsetTop - 20, // Offset by 20px to give some space
                        behavior: 'smooth'
                    });
                }
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [user]);

    // Add window resize listener
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Add scroll effect for desktop only
    useEffect(() => {
        if (!isMobile) {
            const timer = setTimeout(() => {
                const dashboardContent = document.querySelector('.dashboard-content');
                if (dashboardContent) {
                    dashboardContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [isMobile]);

    const getThreatLevel = (pwnCount) => {
        if (pwnCount > 10000000) return 'critical';
        if (pwnCount > 1000000) return 'high';
        if (pwnCount > 100000) return 'medium';
        return 'low';
    };

    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        }
        return num;
    };

    // Helper functions
    const formatScanProgress = (progress) => {
        return `${Math.round(progress)}%`;
    };

    // Add the missing functions
    const fetchStats = async () => {
        try {
            const response = await api.get('/dashboard/stats');
            if (response.data?.success) {
                setDashboardData(prevData => ({
                    ...prevData,
                    stats: {
                        ...prevData.stats,
                        ...response.data.stats
                    }
                }));
            }
        } catch (error) {
            console.error('[Dashboard] Error fetching stats:', error);
            throw error;
        }
    };

    const fetchScanStats = async () => {
        try {
            const response = await api.get('/scan-stats/stats');
            if (response.data?.success) {
                setDashboardData(prevData => ({
                    ...prevData,
                    stats: {
                        ...prevData.stats,
                        sitesScanned: response.data.sitesScanned || 0,
                        profilesFound: response.data.profilesFound || 0,
                        totalMatches: response.data.totalMatches || 0
                    }
                }));
            }
        } catch (error) {
            console.error('[Dashboard] Error fetching scan stats:', error);
            // Set default values instead of throwing
            setDashboardData(prevData => ({
                ...prevData,
                stats: {
                    ...prevData.stats,
                    sitesScanned: 0,
                    profilesFound: 0,
                    totalMatches: 0
                }
            }));
            // Don't throw the error, just return null
            return null;
        }
    };

    const fetchDashboardData = async () => {
        try {
            setIsLoadingInitialData(true);
            setError(null);

            await Promise.all([
                fetchStats().catch(err => {
                    console.error('[Dashboard] Error fetching stats:', err);
                    return null;
                }),
                fetchScanStats().catch(err => {
                    console.error('[Dashboard] Error fetching scan stats:', err);
                    return null;
                })
            ]);

            setIsLoadingInitialData(false);
        } catch (error) {
            console.error('[Dashboard] Error fetching dashboard data:', error);
            setError('Failed to load dashboard data');
            setIsLoadingInitialData(false);
        }
    };

    if (isLoadingInitialData) {
        return <LoadingSpinner />;
    }

    if (error && !dashboardData.stats) {
        return (
            <div className="dashboard-error">
                <h2>Error Loading Dashboard</h2>
                <p>{error}</p>
                <button onClick={fetchDashboardData} className="retry-button">
                    Retry
                </button>
            </div>
        );
    }

    return (
        <>
            <Navbar />
            <MobileNavBar />

            <div className="dashboard-page">
                <Sidebar />

                <div className="content-wrapper">
                    <div className="dashboard-main">
                        {socketErrorState && (
                            <div className="socket-error-banner">
                                <i className="fas fa-exclamation-circle"></i>
                                {socketErrorState}
                                <button 
                                    onClick={() => window.location.reload()} 
                                    className="reconnect-button"
                                >
                                    Reconnect
                                </button>
                            </div>
                        )}
                        
                        <div className="dashboard-content">
                            <DashboardHeader 
                                currentTask={dashboardData.stats.currentSite || 'Starting Soon...'}
                                isScanning={dashboardData.stats.isScanning}
                                onStartScan={startSimulation}
                                onStopScan={stopSimulation}
                            />
                            <div className="dashboard-grid">
                                <ErrorBoundary>
                                    <MemoizedDashboardStats 
                                        stats={{
                                            ...dashboardData.stats,
                                            sitesScanned: formatScanProgress(dashboardData.stats.progress || 0)
                                        }}
                                    />
                                </ErrorBoundary>

                                <ErrorBoundary>
                                    <MemoizedDataBrokerList 
                                        isScanning={isSimulating}
                                        currentSite={currentSite}
                                        progress={simulationProgress}
                                        sitesScanned={metrics.sitesScanned || 0}
                                        profilesFound={metrics.totalMatches || 0}
                                        threatsFound={metrics.potentialThreats || 0}
                                        currentStageFromProps={currentStage}
                                        message={statusMessage}
                                        lastScanTime={dashboardData.stats.lastScanTime}
                                        nextScanTime={dashboardData.stats.nextScanTime}
                                        threats={[]} // You can add actual threats data here if available
                                    />
                                </ErrorBoundary>

                                <ErrorBoundary>
                                    <MemoizedUserInfo 
                                        user={user}
                                    />
                                </ErrorBoundary>

                                <ErrorBoundary>
                                    <MemoizedFeatureToggles 
                                        toggles={dashboardData.featureToggles || {
                                            multi_factor_auth: false,
                                            role_based_access: false,
                                            monitoring_verification: false,
                                            data_leak_notification: false
                                        }}
                                    />
                                </ErrorBoundary>

                                <div className="live-search-section">
                                    <h3>
                                        Live Search
                                        <div className="live-indicator">
                                            <div className="live-dot"></div>
                                            <span>LIVE</span>
                                        </div>
                                    </h3>
                                    <div className="live-search-images">
                                        {liveSearchItems.map(item => (
                                            <div 
                                                key={item.url}
                                                className="image-preview"
                                                onClick={() => window.open(item.url, '_blank')}
                                            >
                                                <div className="preview-header">
                                                    <div className="site-name">{item.siteName}</div>
                                                    <div className="analysis-status">
                                                        <div className="status-dot"></div>
                                                        <span>{item.status}</span>
                                                    </div>
                                                </div>
                                                <div className="preview-content">
                                                    <div className="pulse-dot"></div>
                                                    <span>Monitoring for updates...</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="dashboard-grid-section">
                                    <div className="breach-check-section card">
                                        <h2>
                                            <i className="fas fa-shield-alt" style={{ color: '#D8FF60' }}></i>
                                            Data Breach Monitor
                                        </h2>
                                        <p className="subtitle">Monitoring {user?.email} for data breaches</p>
                                        
                                        {error ? (
                                            <div className="error-message">
                                                <i className="fas fa-exclamation-circle"></i>
                                                {error}
                                            </div>
                                        ) : (
                                            <div className="scanning-message">
                                                <div className="pulse-dot"></div>
                                                <p>
                                                    <i className="fas fa-radar"></i>
                                                    Actively monitoring for data breaches across <span className="database-count">{formatNumber(821)}</span> known breach databases
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(Dashboard);

// Initial state definitions
const initialDashboardState = {
    user: {
        email: "demo@example.com",
        firstName: "John",
        lastName: "Doe",
        memberSince: new Date().toISOString(),
        subscriptionStatus: 'Trial'
    },
    stats: {
        dataBrokerExposure: 75,
        sitesScanned: 0,
        profilesFound: 0,
        totalMatches: 0,
        progress: 0,
        isScanning: false,
        lastScanTime: null,
        nextScanTime: new Date(Date.now() + (7 * 24 * 60 * 60 * 1000)).toISOString(),
        currentSite: null
    },
    featureToggles: {
        multi_factor_auth: false,
        role_based_access: false,
        monitoring_verification: false,
        data_leak_notification: false
    },
    breaches: [] // Added breaches to initial state
};

const initialDataBrokers = [
    { 
        name: "FastPeopleSearch", 
        status: "Pending",
        stage: null,
        message: null
    },
    { 
        name: "TruePeopleSearch", 
        status: "Pending",
        stage: null,
        message: null
    },
    { 
        name: "WhitePages", 
        status: "Pending",
        stage: null,
        message: null
    },
    { 
        name: "Spokeo", 
        status: "Pending",
        stage: null,
        message: null
    }
];

