import { useState, useEffect, useCallback } from 'react';
import { GEMINI_API_KEY, GEMINI_API_URL } from '../config/constants';

export const useChat = () => {
    // State management
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [showWelcome, setShowWelcome] = useState(true);
    const [thinkingStage, setThinkingStage] = useState('');
    const [isAskingName, setIsAskingName] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [messageCount, setMessageCount] = useState(0);
    const STEPS_BEFORE_TOOLS = 2; // Number of exchanges before suggesting tools

    // Generate unique ID for messages
    const generateMessageId = () => `msg_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    // Load messages from localStorage on mount
    useEffect(() => {
        const saved = localStorage.getItem('chatMessages');
        if (saved) {
            try {
                const parsed = JSON.parse(saved);
                if (Array.isArray(parsed)) {
                    // Ensure all loaded messages have IDs
                    const messagesWithIds = parsed.map(msg => ({
                        ...msg,
                        id: msg.id || generateMessageId()
                    }));
                    setMessages(messagesWithIds);
                    setMessageCount(messagesWithIds.filter(m => m.role === 'user').length);
                    setShowWelcome(false);
                }
            } catch (err) {
                console.error('Error loading chatMessages:', err);
            }
        }
    }, []);

    // Save messages to localStorage on update
    useEffect(() => {
        if (messages.length > 0) {
            localStorage.setItem('chatMessages', JSON.stringify(messages));
        }
    }, [messages]);

    // Get tool suggestion text
    const getToolSuggestion = () => {
        return `---TOOLS_SECTION_START---
# Recommended Security Tools
[Data Leak Scanner](/data-leak)
Check if your email has been compromised
[Password Strength Checker](/password-check)
Verify your password security
[Security Assessment](/security-assessment)
Get a personalized security report
---TOOLS_SECTION_END---`;
    };

    // Format response text with HTML
    const formatResponse = (text) => {
        if (!text) return '';
        return formatRegularContent(text);
    };

    // Helper function to format regular content
    const formatRegularContent = (text) => {
        if (!text) return '';
        
        // Split into sections while preserving empty lines
        const sections = text.split(/\n(?:\s*\n)+/).filter(Boolean);
        let formattedContent = '';
        let currentList = [];
        let inList = false;
        
        sections.forEach(section => {
            // Handle headers
            if (section.startsWith('#')) {
                if (inList) {
                    formattedContent += `<ul class="styled-list">${currentList.join('')}</ul>`;
                    currentList = [];
                    inList = false;
                }
                const level = section.match(/^#+/)[0].length;
                const title = section.replace(/^#+\s*/, '');
                formattedContent += `<h${level} class="section-header">${title}</h${level}>`;
                return;
            }
            
            // Split section into lines
            const lines = section.split('\n');
            
            lines.forEach(line => {
                // Handle bullet points
                if (line.trim().startsWith('* ') || line.trim().startsWith('- ')) {
                    if (!inList) inList = true;
                    const content = line.replace(/^[*-]\s+/, '');
                    currentList.push(`<li>${content}</li>`);
                    return;
                }
                
                // Handle numbered lists
                if (/^\d+\./.test(line)) {
                    if (!inList) inList = true;
                    const content = line.replace(/^\d+\.\s+/, '');
                    currentList.push(`<li>${content}</li>`);
                    return;
                }
                
                // Close list if we're not in a list item anymore
                if (inList) {
                    formattedContent += `<ul class="styled-list">${currentList.join('')}</ul>`;
                    currentList = [];
                    inList = false;
                }
                
                // Format regular text
                let formatted = line;
                formatted = formatted.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
                formatted = formatted.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>');
                formatted = formatted.replace(/`([^`]+)`/g, '<span class="highlight">$1</span>');
                
                if (formatted.trim()) {
                    formattedContent += `<p class="animated-text">${formatted}</p>`;
                }
            });
        });
        
        // Close any remaining list
        if (inList) {
            formattedContent += `<ul class="styled-list">${currentList.join('')}</ul>`;
        }
        
        return formattedContent;
    };

    // Typewriter effect implementation
    const typeWriterEffect = async (text, messageId) => {
        const shouldAddTools = messageCount >= STEPS_BEFORE_TOOLS;
        const textWithTools = shouldAddTools ? text + getToolSuggestion() : text;
        
        setIsTyping(true);
        
        let currentText = '';
        const chars = textWithTools.split('');
        
        for (let i = 0; i < chars.length; i++) {
            if (!isTyping) break;
            
            currentText += chars[i];
            const formattedCurrent = formatResponse(currentText);
            
            setMessages(prev => prev.map(msg => 
                msg.id === messageId
                    ? { ...msg, content: formattedCurrent }
                    : msg
            ));
            
            const delay = chars[i].match(/[.,!?]/) ? 50 : 
                         chars[i].match(/[;:]/) ? 40 : 
                         chars[i].match(/\n/) ? 100 :
                         chars[i].match(/\s/) ? 20 : 15;
            
            await new Promise(resolve => setTimeout(resolve, delay));
        }
        
        setIsTyping(false);
    };

    // System prompt for AI
    const getSystemPrompt = () => {
        const conversationContext = messages
            .slice(-4)
            .map(m => `${m.role}: ${m.content}`)
            .join('\n');

        return `You are RavenAI, a world-class cybersecurity and fraud prevention expert. Your responses should:
- Focus exclusively on security, scams, fraud prevention, and data protection
- Be authoritative and precise, drawing from expert knowledge
- Provide actionable, specific advice
- Stay strictly within the security/fraud prevention domain
- Reference legitimate security tools and practices
- Maintain a professional, security-focused tone
- Use security-specific terminology appropriately
- Never discuss topics outside your security expertise
- Never repeat previous responses verbatim
- Provide new, contextual information based on the user's latest message
- If the user provides more details about a situation, analyze those specific details and adjust your advice accordingly
- Use a more urgent and focused tone when responding to immediate threats
- Include specific examples and scenarios when relevant

Important: If this is a follow-up message to a previous question, provide new insights and advice based on the additional information. Do not repeat your previous response.

Current conversation context: ${conversationContext}

Remember: Each response should build upon the conversation and provide fresh, relevant insights based on any new information provided.`;
    };

    // Fetch response from Gemini API
    const fetchGeminiResponse = async (userMsg) => {
        if (!GEMINI_API_KEY) {
            console.error('Gemini API key is missing. Please check your environment variables.');
            return 'I apologize, but I am not properly configured at the moment. Please contact support.';
        }

        try {
            const response = await fetch(`${GEMINI_API_URL}?key=${GEMINI_API_KEY}`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    contents: [{
                        parts: [{ text: userMsg }]
                    }]
                })
            });

            if (!response.ok) {
                const errorData = await response.json().catch(() => ({}));
                console.error('Gemini API error:', {
                    status: response.status,
                    statusText: response.statusText,
                    error: errorData
                });
                throw new Error(`API Error: ${response.status} - ${errorData.error?.message || response.statusText || 'Unknown error'}`);
            }

            const data = await response.json();
            if (!data?.candidates?.[0]?.content?.parts?.[0]?.text) {
                console.error('Invalid Gemini API response format:', data);
                throw new Error('Invalid response format from Gemini API');
            }
            return data.candidates[0].content.parts[0].text;
        } catch (err) {
            console.error("Gemini fetch error:", err);
            if (err.message.includes('API Error: 404')) {
                return 'I apologize, but the AI service is temporarily unavailable. Please try again in a few moments.';
            }
            return err.message.includes('API Error') 
                ? 'I apologize, but I encountered a temporary error. Please try again in a few moments.'
                : 'I apologize, but I am having trouble processing your request. Please try again.';
        }
    };

    // Generate dynamic answer options based on context
    const generateDynamicOptions = async (lastMessage) => {
        if (!GEMINI_API_KEY) return null;

        try {
            const response = await fetch(`${GEMINI_API_URL}?key=${GEMINI_API_KEY}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    contents: [{
                        parts: [{
                            text: `Based on this message from an AI security assistant:
                            "${lastMessage}"
                            
                            Generate 3 possible user responses in this exact JSON format. Return ONLY the raw JSON with no markdown formatting, no code blocks, and no additional text:
                            {
                                "positive": {
                                    "text": "a positive/agreeing response",
                                    "icon": "✅"
                                },
                                "negative": {
                                    "text": "a negative/disagreeing response",
                                    "icon": "❌"
                                },
                                "moreInfo": {
                                    "text": "a response asking for clarification or more details",
                                    "icon": "❓"
                                }
                            }
                            
                            Rules:
                            1. Make responses natural and conversational
                            2. Make responses specific to the context of the message
                            3. Keep each response under 60 characters
                            4. Don't use generic responses
                            5. Return ONLY the JSON object with no additional text or formatting
                            6. Do not include any markdown code blocks or backticks`
                        }]
                    }],
                    generationConfig: {
                        temperature: 0.7,
                        topK: 40,
                        topP: 0.95,
                        maxOutputTokens: 1024
                    }
                })
            });

            if (!response.ok) return null;

            const data = await response.json();
            const responseText = data.candidates[0].content.parts[0].text.trim();
            
            // Clean up the response text to ensure it's valid JSON
            // Remove any markdown formatting, code blocks, or extra whitespace
            const cleanedText = responseText
                .replace(/```json\s*|\s*```/g, '') // Remove code block markers
                .replace(/`/g, '')                 // Remove any backticks
                .trim();                           // Remove extra whitespace
            
            // Try to find the JSON object in the text
            const jsonMatch = cleanedText.match(/\{[\s\S]*\}/);
            if (!jsonMatch) {
                console.error('No valid JSON object found in response');
                return null;
            }

            const jsonStr = jsonMatch[0];
            
            try {
                const options = JSON.parse(jsonStr);
                return [
                    options.positive,
                    options.negative,
                    options.moreInfo
                ];
            } catch (parseError) {
                console.error('JSON parse error:', parseError);
                return null;
            }
        } catch (error) {
            console.error('Error generating options:', error);
            return null;
        }
    };

    // Process user message and get AI response
    const processMessage = async (userMessage) => {
        if (!userMessage.trim()) return;

        const userMessageObj = {
            id: `user-${Date.now()}`,
            role: 'user',
            content: userMessage
        };
        setMessages(prev => [...prev, userMessageObj]);
        setUserInput('');

        const typingMessageId = `assistant-typing-${Date.now()}`;
        setMessages(prev => [...prev, {
            id: typingMessageId,
            role: 'assistant',
            content: '',
            isTyping: true
        }]);
        setIsTyping(true);

        try {
            const [response] = await Promise.all([
                fetchGeminiResponse(userMessage),
                new Promise(resolve => setTimeout(resolve, 1000))
            ]);
            
            setMessages(prev => {
                const filtered = prev.filter(msg => msg.id !== typingMessageId);
                return [...filtered, {
                    id: `assistant-${Date.now()}`,
                    role: 'assistant',
                    content: response
                }];
            });
            
            const options = await generateDynamicOptions(response);
            if (options) {
                setMessages(prev => {
                    const lastMessage = {...prev[prev.length - 1], options};
                    return [...prev.slice(0, -1), lastMessage];
                });
            }
        } catch (error) {
            console.error('Error processing message:', error);
            setMessages(prev => {
                const filtered = prev.filter(msg => msg.id !== typingMessageId);
                return [...filtered, {
                    id: `error-${Date.now()}`,
                    role: 'assistant',
                    content: 'I apologize, but I encountered an error. Please try again.'
                }];
            });
        } finally {
            setIsTyping(false);
        }
    };

    // Handle sending message
    const handleSendMessage = useCallback(async () => {
        if (!userInput.trim()) return;
        await processMessage(userInput);
    }, [userInput]);

    // Handle pre-made question click
    const handlePreMadeQuestionClick = useCallback(async (question) => {
        await processMessage(question);
    }, []);

    // Handle starting new chat
    const handleNewChat = useCallback(() => {
        setIsTyping(false);
        setMessages([]);
        setMessageCount(0);
        setTimeout(() => {
            setShowWelcome(true);
            setThinkingStage('');
            setIsAskingName(false);
            setUserInput('');
            localStorage.removeItem('chatMessages');
        }, 0);
    }, []);

    // Handle option click from multiple choice answers
    const handleOptionClick = useCallback(async (optionText) => {
        if (!optionText.trim()) return;
        await processMessage(optionText);
    }, []);

    return {
        messages,
        userInput,
        showWelcome,
        thinkingStage,
        isAskingName,
        isTyping,
        setUserInput,
        handleSendMessage,
        handlePreMadeQuestionClick,
        handleNewChat,
        handleOptionClick
    };
};

export default useChat; 