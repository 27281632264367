// src/pages/LocationPage.jsx

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './LocationPage.css';
import MobileNavBar from '../components/MobileNavbar';
import Navbar from '../components/Navbar';
import ReviewCarousel from './ReviewCarousel';
import axios from 'axios';

const LocationPage = () => {
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [citySuggestions, setCitySuggestions] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPromptActive, setIsPromptActive] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const queryFirstName = queryParams.get('first');
    const queryLastName = queryParams.get('last');

    const rotatingWords = ['age', 'income', 'address', 'family', 'phone number'];

    // Automatically change the rotating words
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % rotatingWords.length);
        }, 3000);
        return () => clearInterval(intervalId);
    }, []);

    // Check if first and last names are missing from the URL
    useEffect(() => {
        if (!queryFirstName || !queryLastName) {
            setIsPromptActive(true); // Activate the prompt if missing
        } else {
            setFirstName(queryFirstName);
            setLastName(queryLastName);
        }
    }, [queryFirstName, queryLastName]);

    // Only keep the essential effects
    useEffect(() => {
        let isMounted = true;

        const fetchUserLocation = async () => {
            try {
                const response = await axios.get('https://ipapi.co/json/');
                if (isMounted && response.data) {
                    setCity(response.data.city || '');
                    setCountry(response.data.country_name || '');
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Error fetching location:', error);
                }
            }
        };

        fetchUserLocation();

        return () => {
            isMounted = false;
        };
    }, []);

    // Fetch city suggestions from API
    const fetchCitySuggestions = async (input) => {
        if (input.length > 2) {
            try {
                const response = await axios.get(
                    `https://api.teleport.org/api/cities/?search=${input}`
                );
                const cityOptions = response.data._embedded['city:search-results'].map(
                    (item) => item.matching_full_name
                );
                setCitySuggestions(cityOptions);
            } catch (error) {
                console.error('Error fetching city suggestions:', error);
            }
        } else {
            setCitySuggestions([]);
        }
    };

    const handleCityChange = (e) => {
        const input = e.target.value;
        setCity(input);
        fetchCitySuggestions(input);
    };

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handleContinue = () => {
        if (firstName && lastName && city && country) {
            navigate('/scanning', { state: { firstName, lastName, city, country } });
        } else {
            alert('Please provide all required fields.');
        }
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    return (
        <div className="app-container">
            {window.innerWidth <= 768 ? <MobileNavBar /> : <Navbar />}
            <div className="main-content full-screen">
                <div className="global-content-wrapper">
                    <div className="location-container">
                        <div className="location-content">
                            {/* Header */}
                            <div className="header-card">
                                <h1>
                                    Remove information about your&nbsp;
                                    <span className="changing-text">{rotatingWords[currentIndex]}</span>
                                    &nbsp;from Google and 200+ sites.
                                </h1>
                            </div>
                            <p>
                                We will scan 208 data broker sites to find those that expose your personal
                                information.
                            </p>

                            {/* Name Prompt */}
                            {isPromptActive && (
                                <div className="name-prompt">
                                    <div className="name-input-group">
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            value={firstName}
                                            onChange={handleFirstNameChange}
                                            className="location-input"
                                        />
                                        <input
                                            type="text"
                                            placeholder="Last Name"
                                            value={lastName}
                                            onChange={handleLastNameChange}
                                            className="location-input"
                                        />
                                    </div>
                                </div>
                            )}

                            {/* City and Country Input */}
                            <div className="location-input-container">
                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        placeholder="City"
                                        className="location-input"
                                        value={city}
                                        onChange={handleCityChange}
                                    />
                                    {citySuggestions.length > 0 && (
                                        <ul className="suggestions">
                                            {citySuggestions.map((suggestion, index) => (
                                                <li
                                                    key={index}
                                                    onClick={() => {
                                                        setCity(suggestion);
                                                        setCitySuggestions([]);
                                                    }}
                                                    className="suggestion-item"
                                                >
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <input
                                    type="text"
                                    placeholder="Country"
                                    className="location-input"
                                    value={country}
                                    onChange={handleCountryChange}
                                />
                                <button 
                                    onClick={handleContinue} 
                                    className="location-button"
                                    disabled={!city || !country}
                                    style={{ opacity: 1 }}
                                >
                                    Continue
                                </button>
                            </div>

                            {/* Location Info */}
                            <div className="location-info">
                                <p className="location-info-title">
                                    <strong>Why provide your location?</strong>
                                </p>
                                <p className="location-info-description">
                                    By providing your city and country, we tailor our scan to the most relevant
                                    data brokers in your area, giving you <strong>better results</strong> and
                                    ensuring your personal information is <strong>removed faster</strong>.
                                </p>
                                <p className="location-info-cta">
                                    Join the <strong>thousands</strong> of people who have taken control of their
                                    personal information this week!
                                </p>
                            </div>

                            {/* Statistics Section */}
                            <div className="location-stats">
                                <div className="stat-item">
                                    <span className="highlighted-number">3758</span>
                                    <p>People found their profiles this week</p>
                                </div>
                                <div className="stat-item">
                                    <span className="highlighted-number">46</span>
                                    <p>Profiles are found for a person on average</p>
                                </div>
                            </div>

                            {/* Review Carousel Section */}
                            <div className="review-section">
                                <ReviewCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationPage;
