// App.js

import React, { useEffect } from 'react';
import { Route, Routes, useLocation, Link, Navigate } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import Dashboard from './pages/Dashboard';
import PaymentPage from './pages/PaymentPage';
import EditInfoPage from './pages/EditInfoPage';
import SuccessPage from './pages/SuccessPage';
import DataRemovalsPage from './pages/DataRemovalsPage';
import SupportPage from './pages/SupportPage';
import LocationPage from './pages/LocationPage';
import ScanningPage from './pages/ScanningPage';
import TrialSignupPage from './pages/TrialSignupPage';
import DataLeakPage from './pages/DataLeakPage';
import PasswordCheckPage from './pages/PasswordCheckPage';
import FileScanPage from './pages/FileScanPage';
import ChatPage from './pages/ChatPage';
import ResultsPage from './pages/ResultsPage';
import SitesPage from './pages/SitesPage';
import ChangePlanPage from './pages/ChangePlanPage';
import Navbar from './components/Navbar';
import MobileNavbar from './components/MobileNavbar';
import Sidebar from './components/Sidebar';
import useWindowSize from './hooks/useWindowSize';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AuthProvider, useAuth } from './context/AuthContext';
import LoadingSpinner from './components/LoadingSpinner';
import DeleteAccountPage from './pages/DeleteAccountPage';
import DeleteAccountMainPage from './pages/DeleteAccountMainPage';
import { initGA, logPageView } from './utils/analytics';
import AreaCodeMainPage from './pages/AreaCodeMainPage';
import AreaCodeDetailPage from './pages/AreaCodeDetailPage';
import DoorStepProtectionPage from './pages/DoorStepProtectionPage';
import { Footer } from './components/footer';
import AdminDashboard from './pages/AdminDashboard';
import UserManagement from './components/admin/UserManagement';
import ThreatManagement from './components/admin/ThreatManagement';
import SetupPasswordPage from './pages/SetupPasswordPage';
import AuthNavigationHandler from './components/AuthNavigationHandler';

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, loading } = useAuth();
    const isDevelopment = process.env.NODE_ENV === 'development';

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!isAuthenticated && !isDevelopment) {
        return <Navigate to="/login" />;
    }

    return children;
};

const AdminRoute = ({ children }) => {
    const { isAuthenticated, isAdmin, loading } = useAuth();
    const location = useLocation();

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!isAuthenticated || !isAdmin) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

const AppContent = React.memo(() => {
    const location = useLocation();
    const { isAuthenticated, loading } = useAuth();
    const { width } = useWindowSize();
    const isDevelopment = process.env.NODE_ENV === 'development';

    useEffect(() => {
        logPageView();
    }, [location.pathname, location.search]);

    if (loading) {
        return <div>Loading...</div>;
    }

    const hideSidebarPages = [
        '/location',
        '/scanning',
        '/signup',
        '/data-leak',
        '/password-check',
        '/file-scan',
        '/support',
        '/login',
        '/scamai',
        '/results',
        '/area-codes',
        '/doorstep-protection',
        '/delete-account',
        '/setup-password'
    ];

    const shouldHideSidebar = 
        hideSidebarPages.some(page => location.pathname.startsWith(page)) || 
        location.pathname.startsWith('/area-codes/');

    const shouldHideNavbar = hideSidebarPages.includes(location.pathname);

    return (
        <div className={`app-container ${shouldHideSidebar ? 'full-screen' : ''}`}>
            {!shouldHideSidebar && (width > 768 ? <Sidebar /> : <MobileNavbar />)}
            <div className={`main-content ${!shouldHideSidebar ? 'with-sidebar' : ''} ${shouldHideSidebar ? 'full-screen' : ''}`}>
                {width > 768 && !shouldHideSidebar && <Navbar />}
                <Routes>
                    {/* Public Routes */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/sites" element={<SitesPage />} />
                    <Route path="/support" element={<SupportPage />} />
                    <Route path="/data-removals" element={<DataRemovalsPage />} />
                    <Route path="/admin" element={<AdminDashboard />} />
                    <Route path="/admin/users" element={<UserManagement />} />
                    <Route path="/admin/threats" element={<ThreatManagement />} />

                    {/* Protected Routes */}
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    
                    <Route
                        path="/change-plan"
                        element={
                            <PrivateRoute>
                                <ChangePlanPage />
                            </PrivateRoute>
                        }
                    />
                    
                    {/* Edit Info Routes */}
                    <Route path="/edit-info/:token" element={<EditInfoPage />} />
                    <Route
                        path="/edit-info"
                        element={
                            <PrivateRoute>
                                <EditInfoPage />
                            </PrivateRoute>
                        }
                    />

                    {/* Signup Page */}
                    <Route
                        path="/signup"
                        element={
                            <>
                                <Helmet>
                                    <title>Sign Up - Protect Your Data | Get Started with Data Protection</title>
                                    <meta
                                        name="description"
                                        content="Create an account to secure your personal information and protect your online identity with data removal services."
                                    />
                                </Helmet>
                                <SignupPage />
                            </>
                        }
                    />

                    {/* Success Page */}
                    <Route
                        path="/success"
                        element={
                            <>
                                <Helmet>
                                    <title>Success - Protect Your Data | Data Removal Complete</title>
                                    <meta
                                        name="description"
                                        content="Your data removal request has been successfully completed. Your online privacy is now protected."
                                    />
                                </Helmet>
                                <SuccessPage />
                            </>
                        }
                    />

                    {/* Location Page */}
                    <Route
                        path="/location"
                        element={
                            <>
                                <Helmet>
                                    <title>Location - Protect Your Data | Optimize Data Removal for Your Region</title>
                                    <meta
                                        name="description"
                                        content="Provide your location to tailor data removal services to your specific region and ensure privacy protection."
                                    />
                                </Helmet>
                                <LocationPage />
                            </>
                        }
                    />

                    {/* Scanning Page */}
                    <Route
                        path="/scanning"
                        element={
                            <>
                                <Helmet>
                                    <title>Scanning - Protect Your Data | Searching for Your Personal Information</title>
                                    <meta
                                        name="description"
                                        content="We are scanning online data brokers to identify and remove your personal information for enhanced privacy."
                                    />
                                </Helmet>
                                <ScanningPage />
                            </>
                        }
                    />

                    {/* Results Page */}
                    <Route
                        path="/results"
                        element={
                            <>
                                <Helmet>
                                    <title>Scan Results - Protect Your Data | Potential Threats Found</title>
                                    <meta
                                        name="description"
                                        content="View the results of your data scan and see where your personal information is exposed."
                                    />
                                </Helmet>
                                <ResultsPage />
                            </>
                        }
                    />

                    {/* Trial Signup Page */}
                    <Route
                        path="/trial-signup"
                        element={
                            <>
                                <Helmet>
                                    <title>Free Trial Signup - Protect Your Data | Start Protecting Your Privacy Now</title>
                                    <meta
                                        name="description"
                                        content="Sign up for a free trial to get started with our data protection and privacy services. Remove your online data today."
                                    />
                                </Helmet>
                                <TrialSignupPage />
                            </>
                        }
                    />

                    {/* Data Leak Check Page */}
                    <Route
                        path="/data-leak"
                        element={
                            <>
                                <Helmet>
                                    <title>Data Leak Check - Protect Your Data | Find if Your Data Was Leaked</title>
                                    <meta
                                        name="description"
                                        content="Check if your email was compromised in data breaches using our Have I Been Pwned integration. Secure your privacy today."
                                    />
                                </Helmet>
                                <DataLeakPage />
                            </>
                        }
                    />

                    {/* Password Strength Checker Page */}
                    <Route
                        path="/password-check"
                        element={
                            <>
                                <Helmet>
                                    <title>Password Strength Checker - Protect Your Data | Secure Your Passwords</title>
                                    <meta
                                        name="description"
                                        content="Evaluate the strength of your passwords, receive suggestions for improvement, and generate secure passwords to enhance your online security."
                                    />
                                </Helmet>
                                <PasswordCheckPage />
                            </>
                        }
                    />

                    {/* File Scan Page */}
                    <Route
                        path="/file-scan"
                        element={
                            <>
                                <Helmet>
                                    <title>File Scan - Protect Your Data | Scan Your Files for Privacy</title>
                                    <meta
                                        name="description"
                                        content="Perform a file scan to identify and protect sensitive information within your documents. Enhance your privacy with our scanning services."
                                    />
                                </Helmet>
                                <FileScanPage />
                            </>
                        }
                    />

                    {/* Scam AI Chat Page */}
                    <Route
                        path="/scamai"
                        element={
                            <>
                                <Helmet>
                                    <title>Scam AI - Protect Your Data | Prevent Scams</title>
                                    <meta
                                        name="description"
                                        content="Chat with our AI to detect potential scams and protect yourself from fraud."
                                    />
                                </Helmet>
                                <ChatPage />
                            </>
                        }
                    />

                    {/* Delete Account Page */}
                    <Route
                        path="/delete-account"
                        element={
                            <>
                                <Helmet>
                                    <title>Delete Account - Protect Your Data | Delete Your Account</title>
                                    <meta
                                        name="description"
                                        content="Delete your Protect Your Data account to remove all your personal information and data from our system."
                                    />
                                </Helmet>
                                <DeleteAccountMainPage />
                            </>
                        }
                    />

                    {/* Delete Account Page */}
                    <Route
                        path="/delete-account/*"
                        element={<DeleteAccountPage />}
                    />

                    {/* Area Codes Page */}
                    <Route
                        path="/area-codes"
                        element={
                            <>
                                <Helmet>
                                    <title>Area Codes - Protect Your Data | Manage Your Area Codes</title>
                                    <meta
                                        name="description"
                                        content="Manage your area codes to tailor data removal services to your specific region and ensure privacy protection."
                                    />
                                </Helmet>
                                <AreaCodeMainPage />
                            </>
                        }
                    />

                    {/* Area Code Detail Page */}
                    <Route
                        path="/area-codes/:areaCode"
                        element={
                            <>
                                <Helmet>
                                    <title>Area Code Detail - Protect Your Data | Manage Your Area Code</title>
                                    <meta
                                        name="description"
                                        content="Manage your area code to tailor data removal services to your specific region and ensure privacy protection."
                                    />
                                </Helmet>
                                <AreaCodeDetailPage />
                            </>
                        }
                    />

                    {/* DoorStepProtection Page */}
                    <Route
                        path="/doorstep-protection"
                        element={
                            <>
                                <Helmet>
                                    <title>DoorStepProtection - Protect Your Data | Protect Your Data</title>
                                    <meta
                                        name="description"
                                        content="Protect your data with DoorStepProtection."
                                    />
                                </Helmet>
                                <DoorStepProtectionPage />
                            </>
                        }
                    />

                    {/* Setup Password Route */}
                    <Route
                        path="/setup-password/:token"
                        element={
                            <>
                                <Helmet>
                                    <title>Set Up Password - CleanData | Secure Your Account</title>
                                    <meta
                                        name="description"
                                        content="Set up your password to secure your CleanData account and protect your personal information."
                                    />
                                </Helmet>
                                <SetupPasswordPage />
                            </>
                        }
                    />

                    {/* Default Route */}
                    <Route path="/" element={
                        isAuthenticated ? 
                            <Navigate to="/dashboard" replace /> : 
                            <Navigate to="/login" replace />
                    } />
                </Routes>
                {location.pathname !== '/scamai' && <Footer />}
            </div>
        </div>
    );
});

function App() {
    useEffect(() => {
        initGA();
    }, []);

    return (
        <HelmetProvider>
            <AuthProvider>
                <React.Suspense fallback={<div>Loading...</div>}>
                    <AuthNavigationHandler />
                    <AppContent />
                </React.Suspense>
            </AuthProvider>
        </HelmetProvider>
    );
}

const styles = {
    notFoundPage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '80vh',
        color: '#fff',
        textAlign: 'center',
        backgroundColor: '#1e1e1e',
        padding: '20px',
    },
    homeLink: {
        marginTop: '20px',
        padding: '10px 20px',
        backgroundColor: '#00ff85',
        color: '#000',
        textDecoration: 'none',
        borderRadius: '5px',
        fontSize: '1rem',
        transition: 'background-color 0.3s ease',
    }
};

export default App;
