export const scamKeywords = [
    "doorstep", "elderly", "contractor", "repairs", "overcharged", "cash", 
    "home improvement", "roof", "driveway", "garden", "urgent work", "lottery", 
    "cheque", "IRS", "fees", "money", "bank details", "password", "credit card", 
    "wire transfer", "breach", "scam", "leaked", "suspicious", "unknown number", 
    "international", "government", "payment", "caller ID", "spoofed", "fraud",
    "urgent", "threat", "compromise"
];

export const preMadeQuestions = [
    {
        text: "Someone knocked on my door and charged me too much for work. What should I do?",
        icon: "🏠"
    },
    {
        text: "I received a call from this number. How do I check if it's a scam?",
        icon: "📞"
    },
    {
        text: "I suspect my email was leaked. What should I do?",
        icon: "📧"
    },
    {
        text: "I'm worried about old accounts I'm not using anymore. Should I delete them?",
        icon: "🗑️"
    },
    {
        text: "Someone sent me a link to a virus scanner. Is it safe?",
        icon: "🔍"
    },
    {
        text: "I got a notification that my password might be compromised. How do I check?",
        icon: "🔒"
    },
    {
        text: "Someone claims they found my info on a data broker site. How do I remove it?",
        icon: "🛡️"
    }
]; 