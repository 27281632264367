import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
  LinearProgress,
  Autocomplete,
} from '@mui/material';
import socketService from '../../services/socketService';
import { format } from 'date-fns';
import Navbar from '../Navbar';
import MobileNavbar from '../MobileNavbar';
import Sidebar from '../Sidebar';
import './ThreatManagement.css';

const ADMIN_API = 'https://cleandata-api-25456108afa7.herokuapp.com/api/admin';
const DASHBOARD_API = 'https://cleandata-api-25456108afa7.herokuapp.com/api';

const ThreatManagement = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [threatType, setThreatType] = useState('data_breach');
  const [severity, setSeverity] = useState('medium');
  const [details, setDetails] = useState('');
  const [scanProgress, setScanProgress] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [threats, setThreats] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch users for dropdown
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${ADMIN_API}/users`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }

        const data = await response.json();
        setUsers(data.users.map(user => ({
          id: user.id,
          label: `${user.first_name || ''} ${user.last_name || ''} (${user.email})`,
          email: user.email
        })));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Socket connection setup
  useEffect(() => {
    let isMounted = true;

    const initializeSocket = async () => {
      try {
        await socketService.connect();

        socketService.on('threat_detected', (threat) => {
          if (isMounted) {
            setThreats(prev => [...prev, threat]);
          }
        });

        socketService.on('threat_resolved', (threatId) => {
          if (isMounted) {
            setThreats(prev => prev.filter(t => t.id !== threatId));
          }
        });
      } catch (error) {
        console.error('Failed to initialize socket:', error);
        setError('Failed to connect to real-time updates');
      }
    };

    initializeSocket();

    return () => {
      isMounted = false;
      socketService.off('threat_detected');
      socketService.off('threat_resolved');
    };
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) {
      console.log('No date string provided');
      return 'N/A';
    }
    
    try {
      // Parse the ISO date string
      const date = new Date(dateString);
      
      // Check if the date is valid
      if (isNaN(date.getTime())) {
        console.log('Invalid date:', dateString);
        return 'N/A';
      }
      
      // Format the date using date-fns
      return format(date, 'MMM dd, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };

  const handleAddThreat = async () => {
    if (!selectedUser) {
      setError('Please select a user');
      return;
    }

    try {
      const response = await fetch(`${ADMIN_API}/threats/${selectedUser.id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          threatType,
          severity,
          details,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add threat');
      }

      setSuccess('Threat added successfully');
      setDetails('');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message);
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleSimulateScan = async () => {
    if (!selectedUser) {
      setError('Please select a user');
      return;
    }

    try {
      // Start the simulated scan
      const simulateResponse = await fetch(`${ADMIN_API}/simulate-scan/${selectedUser.id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          scanType: 'full',
          isSimulated: true,
          dataBrokers: [
            { name: "FastPeopleSearch", status: "Scanning..." },
            { name: "TruePeopleSearch", status: "Queued" },
            { name: "Spokeo", status: "Queued" },
            { name: "WhitePages", status: "Queued" },
            { name: "BeenVerified", status: "Queued" }
          ]
        }),
      });

      if (!simulateResponse.ok) {
        throw new Error('Failed to start scan simulation');
      }

      // Update user's scan status
      const updateStatusResponse = await fetch(`${DASHBOARD_API}/users/${selectedUser.id}/scan-status`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 'scanning',
          lastScanStarted: new Date().toISOString(),
          estimatedTime: '2-4 days',
          currentTask: 'Monitoring removal status across all data brokers...',
          progress: 0,
          currentSite: 'Starting scan...'
        }),
      });

      if (!updateStatusResponse.ok) {
        throw new Error('Failed to update scan status');
      }

      // Emit socket events for real-time updates
      socketService.emit('scan_started', {
        userId: selectedUser.id,
        scanType: 'simulated',
        timestamp: new Date().toISOString(),
        dataBrokers: [
          { name: "FastPeopleSearch", status: "Scanning..." },
          { name: "TruePeopleSearch", status: "Queued" },
          { name: "Spokeo", status: "Queued" },
          { name: "WhitePages", status: "Queued" },
          { name: "BeenVerified", status: "Queued" }
        ]
      });

      socketService.emit('user_scan_started', {
        userId: selectedUser.id,
        scanType: 'simulated',
        timestamp: new Date().toISOString()
      });

      // Show success message
      setSuccess('Scan simulation started successfully');
      setTimeout(() => setSuccess(null), 3000);

      // Update scan progress in the UI
      setScanProgress({
        progress: 0,
        message: 'Starting scan...'
      });

    } catch (err) {
      console.error('Scan error:', err);
      setError(err.message || 'Failed to start scan');
      setTimeout(() => setError(null), 3000);
    }
  };

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      color: '#fff',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      '& fieldset': {
        borderColor: 'rgba(255, 255, 255, 0.1)',
      },
      '&:hover fieldset': {
        borderColor: '#D8FF60',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#D8FF60',
      },
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-focused': {
        color: '#D8FF60',
      },
    },
    '& .MuiSelect-icon': {
      color: '#D8FF60',
    },
  };

  return (
    <div className="app-layout">
      <Navbar />
      <MobileNavbar />
      <Sidebar />
      <div className="main-content">
        <Box sx={{ color: '#fff', p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{ 
                backgroundColor: '#1E1E1E',
                border: '1px solid rgba(216, 255, 96, 0.05)',
                boxShadow: 'none',
                '&:hover': {
                  border: '1px solid rgba(216, 255, 96, 0.1)',
                }
              }}>
                <CardContent sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom sx={{ 
                    color: '#fff',
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 3
                  }}>
                    Add Simulated Threat
                  </Typography>
                  
                  <Box component="form" sx={{ '& > *': { mb: 2.5 } }}>
                    <Autocomplete
                      value={selectedUser}
                      onChange={(event, newValue) => {
                        setSelectedUser(newValue);
                      }}
                      options={users}
                      loading={loading}
                      getOptionLabel={(option) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select User"
                          sx={inputStyles}
                        />
                      )}
                      sx={{
                        '& .MuiAutocomplete-popupIndicator': {
                          color: '#D8FF60',
                        },
                      }}
                    />

                    <FormControl fullWidth>
                      <InputLabel>Threat Type</InputLabel>
                      <Select
                        value={threatType}
                        onChange={(e) => setThreatType(e.target.value)}
                        sx={inputStyles}
                      >
                        <MenuItem value="data_breach">Data Breach</MenuItem>
                        <MenuItem value="identity_theft">Identity Theft</MenuItem>
                        <MenuItem value="social_media_exposure">Social Media Exposure</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel>Severity</InputLabel>
                      <Select
                        value={severity}
                        onChange={(e) => setSeverity(e.target.value)}
                        sx={inputStyles}
                      >
                        <MenuItem value="low">Low</MenuItem>
                        <MenuItem value="medium">Medium</MenuItem>
                        <MenuItem value="high">High</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      label="Threat Details"
                      multiline
                      rows={4}
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}
                      fullWidth
                      sx={inputStyles}
                    />

                    <Button
                      variant="contained"
                      onClick={handleAddThreat}
                      fullWidth
                      sx={{
                        backgroundColor: '#D8FF60',
                        color: '#000',
                        fontWeight: 600,
                        py: 1.5,
                        '&:hover': {
                          backgroundColor: '#b3ff00',
                        },
                      }}
                    >
                      Add Threat
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card sx={{ 
                backgroundColor: '#1E1E1E',
                border: '1px solid rgba(216, 255, 96, 0.05)',
                boxShadow: 'none',
                '&:hover': {
                  border: '1px solid rgba(216, 255, 96, 0.1)',
                }
              }}>
                <CardContent sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom sx={{ 
                    color: '#fff',
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    mb: 3
                  }}>
                    Scan Simulation
                  </Typography>

                  <Box sx={{ '& > *': { mb: 2.5 } }}>
                    <Autocomplete
                      value={selectedUser}
                      onChange={(event, newValue) => {
                        setSelectedUser(newValue);
                      }}
                      options={users}
                      loading={loading}
                      getOptionLabel={(option) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select User"
                          sx={inputStyles}
                        />
                      )}
                      sx={{
                        '& .MuiAutocomplete-popupIndicator': {
                          color: '#D8FF60',
                        },
                      }}
                    />

                    <Button
                      variant="contained"
                      onClick={handleSimulateScan}
                      fullWidth
                      sx={{
                        backgroundColor: '#D8FF60',
                        color: '#000',
                        fontWeight: 600,
                        py: 1.5,
                        '&:hover': {
                          backgroundColor: '#b3ff00',
                        },
                      }}
                    >
                      Start Simulation
                    </Button>

                    {scanProgress && (
                      <Box sx={{ width: '100%', mt: 2 }}>
                        <LinearProgress
                          variant="determinate"
                          value={scanProgress.progress}
                          sx={{
                            backgroundColor: 'rgba(216, 255, 96, 0.1)',
                            height: 8,
                            borderRadius: 4,
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#D8FF60',
                              borderRadius: 4,
                            },
                          }}
                        />
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            color: 'rgba(255, 255, 255, 0.7)', 
                            textAlign: 'center', 
                            mt: 1,
                            fontSize: '0.9rem'
                          }}
                        >
                          {scanProgress.message}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mt: 3,
                backgroundColor: 'rgba(255, 67, 67, 0.1)',
                color: '#ff4343',
                border: '1px solid rgba(255, 67, 67, 0.2)',
                '& .MuiAlert-icon': {
                  color: '#ff4343',
                },
              }}
            >
              {error}
            </Alert>
          )}

          {success && (
            <Alert 
              severity="success"
              sx={{ 
                mt: 3,
                backgroundColor: 'rgba(216, 255, 96, 0.1)',
                color: '#D8FF60',
                border: '1px solid rgba(216, 255, 96, 0.2)',
                '& .MuiAlert-icon': {
                  color: '#D8FF60',
                },
              }}
            >
              {success}
            </Alert>
          )}
        </Box>
      </div>
    </div>
  );
};

export default ThreatManagement; 