import React, { useState, useEffect } from 'react';
import { FaShieldAlt, FaCheck, FaRocket, FaCrown } from 'react-icons/fa';
import { motion } from 'framer-motion';
import './ChangePlanPage.css';
import Navbar from '../components/Navbar';
import MobileNavbar from '../components/MobileNavbar';
import Sidebar from '../components/Sidebar';
import { API_BASE_URL } from '../config';

const ChangePlanPage = () => {
    const [isAnnually, setIsAnnually] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [selectedPlan, setSelectedPlan] = useState(null);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleToggle = () => {
        setIsAnnually(!isAnnually);
    };

    const handleSelectPlan = (plan) => {
        setSelectedPlan(plan);
        // Add your plan selection logic here
    };

    const containerVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6,
                ease: "easeOut",
                staggerChildren: 0.2
            }
        }
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.6, ease: "easeOut" }
        }
    };

    return (
        <div className="app-layout">
            {isMobile ? <MobileNavbar /> : <Navbar />}
            <Sidebar />
            <motion.div 
                className="main-content"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <div className="change-plan-page">
                    <motion.div 
                        className="plan-header"
                        variants={cardVariants}
                    >
                        <h1>Choose Your Protection Level</h1>
                        <p>Select the plan that best fits your privacy needs and take control of your digital footprint</p>
                    </motion.div>

                    <motion.div 
                        className="billing-toggle"
                        variants={cardVariants}
                    >
                        <span className={!isAnnually ? 'active' : ''}>Monthly</span>
                        <label className="toggle-switch">
                            <input 
                                type="checkbox" 
                                checked={isAnnually} 
                                onChange={handleToggle}
                            />
                            <span className="slider"></span>
                        </label>
                        <span className={isAnnually ? 'active' : ''}>
                            Annually <span className="save-badge">Save 30%</span>
                        </span>
                    </motion.div>

                    <motion.div 
                        className="plans-container"
                        variants={containerVariants}
                    >
                        <motion.div 
                            className="plan-card"
                            variants={cardVariants}
                            whileHover={{ y: -8, transition: { duration: 0.3 } }}
                        >
                            <div className="plan-header">
                                <FaShieldAlt className="plan-icon" />
                                <h2>Basic</h2>
                                <div className="price">
                                    <span className="amount">${isAnnually ? '8' : '11'}</span>
                                    <span className="period">/mo</span>
                                </div>
                                <p className="billed-text">
                                    {isAnnually ? 'Billed $96 annually' : 'Billed monthly'}
                                </p>
                            </div>
                            <ul className="features-list">
                                <li><FaCheck /> Remove from 100+ data brokers</li>
                                <li><FaCheck /> Monthly monitoring</li>
                                <li><FaCheck /> Basic support</li>
                                <li><FaCheck /> Personal dashboard</li>
                            </ul>
                            <button 
                                className={`select-plan-button ${selectedPlan === 'basic' ? 'selected' : ''}`}
                                onClick={() => handleSelectPlan('basic')}
                            >
                                Select Basic
                            </button>
                        </motion.div>

                        <motion.div 
                            className="plan-card featured"
                            variants={cardVariants}
                            whileHover={{ y: -8, transition: { duration: 0.3 } }}
                        >
                            <div className="popular-badge">Most Popular</div>
                            <div className="plan-header">
                                <FaRocket className="plan-icon" />
                                <h2>Pro</h2>
                                <div className="price">
                                    <span className="amount">${isAnnually ? '11' : '15'}</span>
                                    <span className="period">/mo</span>
                                </div>
                                <p className="billed-text">
                                    {isAnnually ? 'Billed $132 annually' : 'Billed monthly'}
                                </p>
                            </div>
                            <ul className="features-list">
                                <li><FaCheck /> Remove from 200+ data brokers</li>
                                <li><FaCheck /> Weekly monitoring</li>
                                <li><FaCheck /> Priority support</li>
                                <li><FaCheck /> Custom removal requests</li>
                                <li><FaCheck /> Advanced privacy reports</li>
                            </ul>
                            <button 
                                className={`select-plan-button featured ${selectedPlan === 'pro' ? 'selected' : ''}`}
                                onClick={() => handleSelectPlan('pro')}
                            >
                                Select Pro
                            </button>
                        </motion.div>

                        <motion.div 
                            className="plan-card"
                            variants={cardVariants}
                            whileHover={{ y: -8, transition: { duration: 0.3 } }}
                        >
                            <div className="plan-header">
                                <FaCrown className="plan-icon" />
                                <h2>Enterprise</h2>
                                <div className="price">
                                    <span className="amount">${isAnnually ? '15' : '20'}</span>
                                    <span className="period">/mo</span>
                                </div>
                                <p className="billed-text">
                                    {isAnnually ? 'Billed $180 annually' : 'Billed monthly'}
                                </p>
                            </div>
                            <ul className="features-list">
                                <li><FaCheck /> Remove from 300+ data brokers</li>
                                <li><FaCheck /> Daily monitoring</li>
                                <li><FaCheck /> 24/7 premium support</li>
                                <li><FaCheck /> Advanced protection features</li>
                                <li><FaCheck /> Team management</li>
                                <li><FaCheck /> Custom API access</li>
                            </ul>
                            <button 
                                className={`select-plan-button ${selectedPlan === 'enterprise' ? 'selected' : ''}`}
                                onClick={() => handleSelectPlan('enterprise')}
                            >
                                Select Enterprise
                            </button>
                        </motion.div>
                    </motion.div>
                </div>
            </motion.div>
        </div>
    );
};

export default ChangePlanPage;
