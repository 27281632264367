import React, { memo, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MultipleChoiceAnswers from './MultipleChoiceAnswers';
import SecurityTools from './SecurityTools';

// Animation variants for message appearance/disappearance
const messageVariants = {
    initial: { 
        opacity: 0,
        y: 10,
        height: 0,
        margin: 0
    },
    animate: { 
        opacity: 1,
        y: 0,
        height: "auto",
        margin: "12px 0",
        transition: {
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
        }
    },
    exit: { 
        opacity: 0,
        height: 0,
        margin: 0,
        transition: {
            duration: 0.2,
            ease: [0.4, 0, 0.2, 1]
        }
    }
};

// Function to highlight security-related keywords in the text
const highlightScamKeywords = (text, keywords) => {
    if (!text) return '';
    let highlighted = text;
    keywords.forEach(keyword => {
        const regex = new RegExp(`\\b${keyword}\\b`, 'gi');
        highlighted = highlighted.replace(regex, `<span class="highlight">${keyword}</span>`);
    });
    return highlighted;
};

const ChatMessages = memo(({ messages, scamKeywords, isTyping, onOptionClick }) => {
    const lastMessageRef = useRef(null);

    useEffect(() => {
        if (lastMessageRef.current) {
            const elements = lastMessageRef.current.querySelectorAll('p, ul, h1, h2, h3');
            elements.forEach((element, index) => {
                element.style.setProperty('--reveal-index', index);
            });
            lastMessageRef.current.classList.add('revealing');
        }
    }, [messages]);

    if (!messages || !Array.isArray(messages)) return null;

    // Calculate the number of exchanges (a user message followed by an assistant message)
    const exchanges = messages.reduce((count, message, index) => {
        if (message.role === 'assistant' && index > 0 && messages[index - 1].role === 'user') {
            return count + 1;
        }
        return count;
    }, 0);

    // Show tools after 2 exchanges
    const showTools = exchanges >= 2;

    return (
        <div className="chat-history">
            <AnimatePresence mode="popLayout" initial={false}>
                {/* Render messages */}
                {messages.map((message, index) => {
                    if (!message) return null;
                    
                    const isAssistant = message.role === 'assistant';
                    const messageContent = message.content || '';
                    const isLastMessage = index === messages.length - 1;
                    const messageKey = message.id || `message-${index}-${Date.now()}`;
                    
                    return (
                        <motion.div
                            key={messageKey}
                            className={`${isAssistant ? 'bot-response' : 'user-message'} ${isTyping && isAssistant && !messageContent ? 'typing' : ''}`}
                            variants={messageVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            layout
                            style={{ overflow: 'hidden' }}
                            ref={isLastMessage && isAssistant ? lastMessageRef : null}
                        >
                            {messageContent ? (
                                <>
                                    <div 
                                        className="message-content"
                                        dangerouslySetInnerHTML={{
                                            __html: isAssistant
                                                ? highlightScamKeywords(messageContent, scamKeywords)
                                                : messageContent
                                        }}
                                    />
                                    {isAssistant && isLastMessage && !isTyping && message.options && (
                                        <MultipleChoiceAnswers 
                                            options={message.options}
                                            onOptionClick={onOptionClick}
                                        />
                                    )}
                                </>
                            ) : isAssistant && isTyping ? (
                                <div className="typing-indicator">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            ) : null}
                        </motion.div>
                    );
                })}

                {/* Show SecurityTools after messages if condition is met */}
                {showTools && !isTyping && messages.length > 0 && (
                    <motion.div
                        key="security-tools"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                    >
                        <SecurityTools />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
});

// Set display name for debugging
ChatMessages.displayName = 'ChatMessages';

export default ChatMessages; 