import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
    FaUser, FaEnvelope, FaPhone, FaHome, FaGlobe, FaCity, 
    FaMapPin, FaLock, FaInfoCircle, FaShieldAlt, FaPlus, 
    FaTimes, FaCheck, FaExclamationTriangle, FaCheckCircle, FaEye, FaEyeSlash 
} from 'react-icons/fa';
import './EditInfoPage.css';
import { useAuth } from '../context/AuthContext';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import MobileNavbar from '../components/MobileNavbar';
import { validatePassword } from '../utils/passwordUtils';
import PasswordStrengthMeter from '../components/PasswordStrengthMeter';
import authService from '../services/authService';
import LoadingSpinner from '../components/LoadingSpinner';

const countriesWithStates = [
    'United States',
    'Canada',
    'Australia',
    'India',
    'Brazil',
    'Mexico',
    'Germany',
    'China',
    'Russia'
];

// Always use production URL
const API_BASE_URL = 'https://cleandata-api-25456108afa7.herokuapp.com';

const EditInfoPage = () => {
    const navigate = useNavigate();
    const { token: setupToken } = useParams();
    const { user, setUser, refreshUserData } = useAuth();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        additionalEmails: [],
        additionalPhones: [],
        address: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        password: '',
        confirmPassword: '',
    });

    const [currentSection, setCurrentSection] = useState('personal');
    const [error, setError] = useState('');
    const [sectionProgress, setSectionProgress] = useState({
        personal: { visited: true, completed: false },
        contact: { visited: false, completed: false },
        address: { visited: false, completed: false }
    });
    
    const [loadingOptions, setLoadingOptions] = useState(false);
    const [formStatus, setFormStatus] = useState('unsubmitted');
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [formSection, setFormSection] = useState('personal'); // ['personal', 'contact', 'address']
    const [formProgress, setFormProgress] = useState({
        personal: { completed: false, valid: false },
        contact: { completed: false, valid: false },
        address: { completed: false, valid: false }
    });

    const [options, setOptions] = useState({
        states: [],
        cities: []
    });

    const [isLoading, setIsLoading] = useState(true);

    const [passwordValidationErrors, setPasswordValidationErrors] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        let isMounted = true;
        const abortController = new AbortController();

        const fetchUserData = async () => {
            console.log('Starting fetchUserData with setupToken:', setupToken);
            
            // Always attempt to fetch data when in setup mode, regardless of existing form data
            if (!setupToken && formData.firstName && formData.lastName && formData.email) {
                setIsLoading(false);
                console.log('Form data already populated, skipping fetch');
                return;
            }

            try {
                setIsLoading(true);
                setError('');

                // For setup token flow, we use a different endpoint
                const endpoint = setupToken 
                    ? `${API_BASE_URL}/api/users/setup/${setupToken}`
                    : `${API_BASE_URL}/api/users/details`;

                console.log('Fetching user data from:', endpoint);

                const headers = {
                    'Content-Type': 'application/json',
                    ...(setupToken 
                        ? { 'X-Setup-Token': setupToken }
                        : { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
                    )
                };

                console.log('Using headers:', headers);

                const response = await fetch(endpoint, {
                    method: 'GET',
                    headers: headers,
                    signal: abortController.signal
                });

                console.log('Response status:', response.status);

                if (!response.ok) {
                    const errorData = await response.json().catch(() => ({}));
                    console.error('Error response:', errorData);

                    if (response.status === 401) {
                        if (setupToken) {
                            throw new Error('Invalid or expired setup token. Please request a new setup link.');
                        } else {
                            localStorage.removeItem('token');
                            navigate('/login', { 
                                state: { 
                                    error: 'Session expired. Please log in again.',
                                    returnUrl: window.location.pathname
                                } 
                            });
                            return;
                        }
                    }
                    
                    if (response.status === 404 && setupToken) {
                        throw new Error('Setup token not found. Please request a new setup link.');
                    }

                    throw new Error(errorData.message || 'Failed to fetch user data');
                }

                const userData = await response.json();
                console.log('Raw user data received:', userData);

                if (!isMounted) {
                    console.log('Component unmounted, stopping update');
                    return;
                }

                if (userData.error) {
                    throw new Error(userData.error);
                }

                // Map the backend response to our frontend format
                const userUpdate = {
                    id: userData.id,
                    email: userData.email,
                    firstName: userData.firstName || userData.first_name || '',
                    lastName: userData.lastName || userData.last_name || '',
                    role: userData.role,
                    setupToken: userData.setup_token,
                    setupTokenExpires: userData.setup_token_expires
                };

                console.log('Prepared user update:', userUpdate);

                const formUpdate = {
                    email: userData.email || '',
                    firstName: userData.firstName || userData.first_name || '',
                    lastName: userData.lastName || userData.last_name || '',
                    phoneNumber: userData.phone || '',
                    address: userData.address || '',
                    country: userData.country || '',
                    state: userData.state || '',
                    city: userData.city || '',
                    zipCode: userData.zip_code || '',
                    additionalEmails: userData.additional_emails || [],
                    additionalPhones: userData.additional_phones || [],
                    // Clear password fields in setup mode
                    password: '',
                    confirmPassword: ''
                };

                console.log('Setting form data to:', formUpdate);
                setFormData(formUpdate);

                if (setUser && !setupToken) {
                    console.log('Updating user context with:', userUpdate);
                    setUser(userUpdate);
                    await refreshUserData();
                }

                // In setup mode, start at personal section for password setup
                if (setupToken) {
                    setFormSection('personal');
                    setFormProgress(prev => ({
                        ...prev,
                        personal: { ...prev.personal, completed: false }
                    }));
                }

            } catch (error) {
                console.error('Error in fetchUserData:', error);
                
                if (error.message === 'Failed to fetch' || !window.navigator.onLine) {
                    setError('Unable to connect to the server. Please check your internet connection.');
                } else if (setupToken && (error.message.includes('setup token') || error.message.includes('Setup token'))) {
                    setError(error.message);
                    // Optionally redirect to a specific error page for invalid setup tokens
                    // navigate('/setup-error');
                } else {
                    setError(error.message || 'An error occurred while loading your profile.');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchUserData();

        return () => {
            isMounted = false;
            abortController.abort();
        };
    }, [navigate, setupToken, setUser, formData.firstName, formData.lastName, formData.email]);

    useEffect(() => {
        if (formData.country) {
            handleCountryChange({ target: { value: formData.country } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateSection = (section) => {
        const newErrors = {};
        switch(section) {
            case 'personal':
                if (!formData.firstName?.trim()) newErrors.firstName = 'First name is required';
                if (!formData.lastName?.trim()) newErrors.lastName = 'Last name is required';
                if (setupToken) {
                    if (!formData.password) newErrors.password = 'Password is required';
                    if (!formData.confirmPassword) newErrors.confirmPassword = 'Please confirm your password';
                    if (formData.password !== formData.confirmPassword) {
                        newErrors.confirmPassword = 'Passwords do not match';
                        newErrors.password = 'Passwords do not match';
                    }
                    const passwordErrors = validatePassword(formData.password);
                    if (passwordErrors.length > 0) {
                        newErrors.password = passwordErrors[0];
                        setPasswordValidationErrors(passwordErrors);
                    }
                }
                break;
            case 'contact':
                if (!formData.email?.trim()) newErrors.email = 'Email is required';
                if (!/^\S+@\S+\.\S+$/.test(formData.email)) newErrors.email = 'Invalid email format';
                if (!formData.phoneNumber?.trim()) newErrors.phoneNumber = 'Phone number is required';
                // Validate phone number format
                if (formData.phoneNumber && !/^\+?[\d\s-]{10,}$/.test(formData.phoneNumber)) {
                    newErrors.phoneNumber = 'Please enter a valid phone number';
                }
                // Validate additional emails if they exist
                formData.additionalEmails.forEach((email, index) => {
                    if (email.trim() && !/^\S+@\S+\.\S+$/.test(email)) {
                        newErrors[`additionalEmails_${index}`] = 'Invalid email format';
                    }
                });
                break;
            case 'address':
                console.log('Validating address section with values:', {
                    address: formData.address,
                    country: formData.country,
                    state: formData.state,
                    city: formData.city,
                    zipCode: formData.zipCode
                });
                
                if (!formData.address?.trim()) {
                    newErrors.address = 'Street address is required';
                }
                if (!formData.country?.trim()) {
                    newErrors.country = 'Country is required';
                }
                if (countriesWithStates.includes(formData.country) && !formData.state?.trim()) {
                    newErrors.state = 'State is required';
                }
                if (!formData.city?.trim()) {
                    newErrors.city = 'City is required';
                }
                // ZIP code is optional, no validation needed
                break;
        }
        console.log(`Validation errors for ${section}:`, newErrors);
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));

        // Password-specific validation
        if (name === 'password') {
            const passwordErrors = validatePassword(value);
            setPasswordValidationErrors(passwordErrors);
        }

        // Clear errors
        setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
    };

    const handleCountryChange = async (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            country: value,
            state: '',
            city: ''
        }));
        setOptions({ states: [], cities: [] });
        setLoadingOptions(true);

        try {
            if (countriesWithStates.includes(value)) {
                // Fetch states
                const response = await fetch('https://countriesnow.space/api/v0.1/countries/states', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ country: value }),
                });

                if (!response.ok) throw new Error('Error fetching states');

                const result = await response.json();
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    states: result.data.states.map(state => state.name)
                }));
            } else {
                // Fetch cities
                const response = await fetch('https://countriesnow.space/api/v0.1/countries/cities', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ country: value }),
                });

                if (!response.ok) throw new Error('Error fetching cities');

                const result = await response.json();
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    cities: result.data
                }));
            }
        } catch (error) {
            console.error('Error fetching options:', error);
            setOptions({ states: [], cities: [] });
        } finally {
            setLoadingOptions(false);
        }
    };

    const handleAddField = (field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], ''],
        }));
    };

    const handleRemoveField = (field, index) => {
        setFormData((prevData) => {
            const updatedFields = [...prevData[field]];
            updatedFields.splice(index, 1);
            return { ...prevData, [field]: updatedFields };
        });
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[`${field}_${index}`];
            return updatedErrors;
        });
    };

    const handleAdditionalChange = (field, index, value) => {
        const updatedFields = [...formData[field]];
        updatedFields[index] = value;
        setFormData({ ...formData, [field]: updatedFields });

        // Live validation
        setErrors((prevErrors) => ({ ...prevErrors, [`${field}_${index}`]: undefined }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submit handler called');
        
        // Validate all sections before submission
        console.log('Starting validation of all sections');
        const personalErrors = validateSection('personal');
        const contactErrors = validateSection('contact');
        const addressErrors = validateSection('address');
        
        console.log('Validation results:', {
            personalErrors,
            contactErrors,
            addressErrors
        });
        
        const allErrors = { ...personalErrors, ...contactErrors, ...addressErrors };
        
        if (Object.keys(allErrors).length > 0) {
            console.log('Form validation failed with errors:', allErrors);
            setErrors(allErrors);
            // Set the form section to the first section with errors
            if (Object.keys(personalErrors).length > 0) {
                setFormSection('personal');
            } else if (Object.keys(contactErrors).length > 0) {
                setFormSection('contact');
            } else if (Object.keys(addressErrors).length > 0) {
                setFormSection('address');
            }
            return;
        }

        console.log('Form validation passed, proceeding with submission');
        setIsSubmitting(true);
        setFormStatus('unsubmitted');
        setError('');

        let retryCount = 0;
        const maxRetries = 3;

        while (retryCount < maxRetries) {
            try {
                // Use the correct endpoint URL
                const endpoint = `${API_BASE_URL}/api/users/submit-data`;
                console.log('Submitting to endpoint:', endpoint);

                // Format the data exactly as required by the API
                const submissionData = {
                    firstName: formData.firstName.trim(),
                    lastName: formData.lastName.trim(),
                    email: formData.email.trim(),
                    phoneNumber: formData.phoneNumber?.trim() || null,
                    address: formData.address.trim(),
                    country: formData.country.trim(),
                    city: formData.city.trim(),
                    state: formData.state?.trim() || null,
                    zipCode: formData.zipCode?.trim() || null,
                    additionalEmails: formData.additionalEmails?.filter(email => email.trim()).map(email => email.trim()) || [],
                    additionalPhones: formData.additionalPhones?.filter(phone => phone.trim()).map(phone => phone.trim()) || []
                };

                // Log the exact submission data for debugging
                console.log('Submitting data:', JSON.stringify(submissionData, null, 2));

                // Determine which token to use
                const currentToken = setupToken || localStorage.getItem('token');
                if (!currentToken) {
                    throw new Error('No authentication token found. Please log in again.');
                }

                // Set headers based on token type
                const headers = {
                    'Content-Type': 'application/json',
                    ...(setupToken 
                        ? { 'X-Setup-Token': setupToken }
                        : { 'Authorization': `Bearer ${currentToken}` }
                    )
                };

                console.log('Request headers:', headers);

                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(submissionData)
                });

                console.log('Response status:', response.status);
                
                // Add response text logging for debugging
                const responseText = await response.text();
                console.log('Raw response:', responseText);
                
                let result;
                try {
                    result = JSON.parse(responseText);
                    console.log('Parsed response data:', result);
                } catch (e) {
                    console.error('Failed to parse response as JSON:', e);
                    throw new Error(`Invalid response format from server: ${responseText}`);
                }

                if (!response.ok) {
                    if (response.status === 401) {
                        console.error('Authentication error:', result);
                        localStorage.removeItem('token');
                        throw new Error('Authentication failed. Please log in again.');
                    } else if (response.status === 400) {
                        console.error('Validation error:', result);
                        if (result.fields) {
                            throw new Error(`Missing required fields: ${result.fields.join(', ')}`);
                        }
                        throw new Error(result.error || 'Invalid form data submitted');
                    } else if (response.status === 403) {
                        console.error('Authorization error:', result);
                        throw new Error('You do not have permission to perform this action');
                    } else if (response.status === 429) {
                        console.error('Rate limit exceeded:', result);
                        throw new Error('Too many requests. Please try again later.');
                    } else if (response.status === 500) {
                        console.error('Server error:', result);
                        if (retryCount < maxRetries - 1) {
                            retryCount++;
                            console.log(`Retrying submission (attempt ${retryCount + 1} of ${maxRetries})...`);
                            // Wait before retrying (exponential backoff)
                            await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retryCount)));
                            continue;
                        }
                        throw new Error('Server error persists after retries. Please try again later.');
                    }

                    throw new Error(result.error || result.message || `Server returned ${response.status}: ${responseText}`);
                }

                // Handle successful response
                if (result.success) {
                    setFormStatus('submitted');
                    setError('');
                    
                    // Show success message
                    setFormStatus('submitted');
                    setError(result.message || 'Profile updated successfully!');

                    // Navigate to dashboard after a short delay
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 1500);
                } else {
                    throw new Error(result.error || 'Submission failed without error message');
                }

                // If we get here, submission was successful
                break;

            } catch (error) {
                console.error('Form submission error:', error);
                if (retryCount === maxRetries - 1) {
                    setFormStatus('error');
                    setError(error.message || 'An error occurred while submitting the form. Please try again.');
                    break;
                }
                retryCount++;
                console.log(`Retrying submission (attempt ${retryCount + 1} of ${maxRetries})...`);
                // Wait before retrying (exponential backoff)
                await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retryCount)));
            }
        }

        setIsSubmitting(false);
    };

    const handleSectionChange = (section) => {
        if (formProgress[section].completed || section === formSection) {
            setFormSection(section);
        }
    };

    const handlePrevSection = () => {
        switch(formSection) {
            case 'contact':
                setFormSection('personal');
                break;
            case 'address':
                setFormSection('contact');
                break;
            default:
                break;
        }
    };

    const handleNextSection = () => {
        console.log('handleNextSection called');
        const currentSectionValid = validateSection(formSection);
        console.log('Current section validation errors:', currentSectionValid);
        if (Object.keys(currentSectionValid).length === 0) {
            switch(formSection) {
                case 'personal':
                    setFormSection('contact');
                    setFormProgress(prev => ({
                        ...prev,
                        personal: { ...prev.personal, completed: true }
                    }));
                    break;
                case 'contact':
                    setFormSection('address');
                    setFormProgress(prev => ({
                        ...prev,
                        contact: { ...prev.contact, completed: true }
                    }));
                    break;
                default:
                    break;
            }
        } else {
            setErrors(currentSectionValid);
        }
    };

    const renderAdditionalEmails = () => (
        <div className="additional-fields-container">
            {formData.additionalEmails.map((email, index) => (
                <div key={`email-${index}`} className="additional-field">
                    <div className="form-field">
                        <label htmlFor={`additional-email-${index}`}>
                            <FaEnvelope className="field-icon" />
                            Additional Email {index + 1}
                        </label>
                        <div className="input-with-action">
                            <input
                                type="email"
                                id={`additional-email-${index}`}
                                value={email}
                                onChange={(e) => handleAdditionalChange('additionalEmails', index, e.target.value)}
                                className={errors[`additionalEmails_${index}`] ? 'error' : ''}
                            />
                            <button 
                                type="button" 
                                className="remove-field"
                                onClick={() => handleRemoveField('additionalEmails', index)}
                            >
                                <FaTimes />
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    const renderAddressSection = () => (
        <div className="form-section">
            <h2>Address Information</h2>
            <div className="address-fields">
                <div className="form-field full-width">
                    <label htmlFor="address">
                        <FaHome className="field-icon" />
                        Street Address
                    </label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className={errors.address ? 'error' : ''}
                        placeholder="Enter your street address"
                    />
                </div>
                
                <div className="select-container">
                    <select
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleCountryChange}
                        className={errors.country ? 'error' : ''}
                        disabled={loadingOptions}
                    >
                        <option value="">Select Country</option>
                        {countriesWithStates.map(country => (
                            <option key={country} value={country}>{country}</option>
                        ))}
                    </select>
                </div>

                {formData.country && options.states.length > 0 && (
                    <div className="select-container">
                        <select
                            id="state"
                            name="state"
                            value={formData.state}
                            onChange={handleStateChange}
                            className={errors.state ? 'error' : ''}
                            disabled={loadingOptions}
                        >
                            <option value="">Select State</option>
                            {options.states.map(state => (
                                <option key={state} value={state}>{state}</option>
                            ))}
                        </select>
                    </div>
                )}

                {formData.state && options.cities.length > 0 && (
                    <div className="select-container">
                        <select
                            id="city"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            className={errors.city ? 'error' : ''}
                            disabled={loadingOptions}
                        >
                            <option value="">Select City</option>
                            {options.cities.map(city => (
                                <option key={city} value={city}>{city}</option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
        </div>
    );

    const renderPasswordFields = () => (
        <>
            <div className="form-field">
                <label htmlFor="password">
                    <FaLock className="field-icon" />
                    Password
                </label>
                <div className="password-input-container">
                    <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className={errors.password ? 'error' : ''}
                        placeholder="Enter your password"
                    />
                    <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                </div>
                {errors.password && <span className="error-message">{errors.password}</span>}
                <PasswordStrengthMeter password={formData.password} />
                {passwordValidationErrors.length > 0 && (
                    <div className="password-requirements">
                        {passwordValidationErrors.map((error, index) => (
                            <div key={index} className="requirement">
                                {error}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="form-field">
                <label htmlFor="confirmPassword">
                    <FaLock className="field-icon" />
                    Confirm Password
                </label>
                <div className="password-input-container">
                    <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className={errors.confirmPassword ? 'error' : ''}
                        placeholder="Confirm your password"
                    />
                    <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                </div>
                {errors.confirmPassword && <span className="error-message">{errors.confirmPassword}</span>}
            </div>
        </>
    );

    const renderFormSection = () => {
        switch(formSection) {
            case 'personal':
                return (
                    <div className="form-section">
                        <h2>
                            <FaUser className="section-icon" />
                            Personal Information
                        </h2>
                        <div className="input-group">
                            <div className="form-field">
                                <label htmlFor="firstName">
                                    <FaUser className="field-icon" />
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    className={errors.firstName ? 'error' : ''}
                                />
                                {errors.firstName && <span className="error-message">{errors.firstName}</span>}
                            </div>
                            <div className="form-field">
                                <label htmlFor="lastName">
                                    <FaUser className="field-icon" />
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    className={errors.lastName ? 'error' : ''}
                                />
                                {errors.lastName && <span className="error-message">{errors.lastName}</span>}
                            </div>
                            {setupToken && renderPasswordFields()}
                        </div>
                    </div>
                );

            case 'contact':
                return (
                    <div className="form-section">
                        <h2>
                            <FaEnvelope className="section-icon" />
                            Contact
                        </h2>
                        <div className="form-fields">
                            <div className="form-field">
                                <label htmlFor="email">
                                    <FaEnvelope className="field-icon" />
                                    Email Address
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={errors.email ? 'error' : ''}
                                    placeholder="Enter your email address"
                                />
                                {errors.email && <span className="error-message">{errors.email}</span>}
                            </div>
                            <div className="form-field">
                                <label htmlFor="phoneNumber">
                                    <FaPhone className="field-icon" />
                                    Phone Number
                                </label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    className={errors.phoneNumber ? 'error' : ''}
                                    placeholder="Enter your phone number"
                                />
                                {errors.phoneNumber && <span className="error-message">{errors.phoneNumber}</span>}
                            </div>
                            <div className="additional-fields">
                                <button type="button" onClick={() => handleAddField('additionalEmails')} className="add-button">
                                    <FaPlus /> Add Another Email
                                </button>
                                {formData.additionalEmails.length > 0 && renderAdditionalEmails()}
                            </div>
                        </div>
                    </div>
                );

            case 'address':
                return (
                    <div className="form-section">
                        <h2>
                            <FaHome className="section-icon" />
                            Address
                        </h2>
                        <div className="input-group">
                            <div className="form-field full-width">
                                <label htmlFor="address">
                                    <FaHome className="field-icon" />
                                    Street Address
                                </label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    className={errors.address ? 'error' : ''}
                                    placeholder="Enter your street address"
                                />
                            </div>
                            
                            <div className="select-container">
                                <select
                                    id="country"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleCountryChange}
                                    className={errors.country ? 'error' : ''}
                                    disabled={loadingOptions}
                                >
                                    <option value="">Select Country</option>
                                    {countriesWithStates.map(country => (
                                        <option key={country} value={country}>{country}</option>
                                    ))}
                                </select>
                            </div>

                            {formData.country && options.states.length > 0 && (
                                <div className="select-container">
                                    <select
                                        id="state"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleStateChange}
                                        className={errors.state ? 'error' : ''}
                                        disabled={loadingOptions}
                                    >
                                        <option value="">Select State</option>
                                        {options.states.map(state => (
                                            <option key={state} value={state}>{state}</option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            {formData.state && options.cities.length > 0 && (
                                <div className="select-container">
                                    <select
                                        id="city"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        className={errors.city ? 'error' : ''}
                                        disabled={loadingOptions}
                                    >
                                        <option value="">Select City</option>
                                        {options.cities.map(city => (
                                            <option key={city} value={city}>{city}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    const handleStateChange = async (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            state: value,
            city: ''
        }));
        setLoadingOptions(true);

        try {
            const response = await fetch('https://countriesnow.space/api/v0.1/countries/state/cities', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    country: formData.country,
                    state: value
                }),
            });

            if (!response.ok) throw new Error('Error fetching cities');

            const result = await response.json();
            if (result.data) {
                setOptions(prev => ({
                    ...prev,
                    cities: result.data.sort()
                }));
            }
        } catch (error) {
            console.error('Error fetching cities:', error);
            setOptions(prev => ({
                ...prev,
                cities: []
            }));
        } finally {
            setLoadingOptions(false);
        }
    };

    const WelcomeBanner = () => (
        <div className="welcome-banner">
            <div className="welcome-content">
                <FaCheckCircle className="success-icon" />
                <h1>Welcome to CleanData Pro!</h1>
                <p>Congratulations on taking the first step towards better privacy protection.</p>
                <p>Once you complete your profile, we'll begin daily scans to ensure your personal information stays protected.</p>
            </div>
        </div>
    );

    return (
        <div className="app-layout">
            {!setupToken && <Sidebar />}
            <div className="main-content">
                {!setupToken && <Navbar />}
                <div className="edit-info-page">
                    <div className="edit-info-container">
                        {isLoading ? (
                            <LoadingSpinner />
                        ) : (
                            <>
                                {error && (
                                    <div className="error-banner">
                                        <FaExclamationTriangle />
                                        {error}
                                    </div>
                                )}
                                
                                {setupToken && (
                                    <div className="security-banner">
                                        <FaShieldAlt className="shield-icon" />
                                        Secure your account by completing your profile
                                    </div>
                                )}

                                <div className="form-progress">
                                    <button
                                        className={`progress-step ${formSection === 'personal' ? 'active' : ''} ${formProgress.personal.completed ? 'completed' : ''}`}
                                        onClick={() => handleSectionChange('personal')}
                                    >
                                        Personal
                                    </button>
                                    <button
                                        className={`progress-step ${formSection === 'contact' ? 'active' : ''} ${formProgress.contact.completed ? 'completed' : ''}`}
                                        onClick={() => handleSectionChange('contact')}
                                    >
                                        Contact
                                    </button>
                                    <button
                                        className={`progress-step ${formSection === 'address' ? 'active' : ''} ${formProgress.address.completed ? 'completed' : ''}`}
                                        onClick={() => handleSectionChange('address')}
                                    >
                                        Address
                                    </button>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    {renderFormSection()}
                                    
                                    <div className="form-navigation">
                                        <div className="nav-buttons">
                                            {formSection !== 'personal' && (
                                                <button
                                                    type="button"
                                                    className="prev-button"
                                                    onClick={handlePrevSection}
                                                >
                                                    Previous
                                                </button>
                                            )}
                                            <button
                                                type="button"
                                                className={`next-button ${
                                                    formSection === 'address' && 
                                                    Object.keys(validateSection('address')).length > 0 ? 
                                                    'disabled' : ''
                                                }`}
                                                onClick={(e) => {
                                                    console.log('Button clicked');
                                                    console.log('Form section:', formSection);
                                                    console.log('Form data:', formData);
                                                    if (formSection === 'address') {
                                                        handleSubmit(e);
                                                    } else {
                                                        handleNextSection();
                                                    }
                                                }}
                                                disabled={formSection === 'address' && Object.keys(validateSection('address')).length > 0}
                                            >
                                                {formSection === 'address' ? 'Save Changes' : 'Next'}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                    
                    {showPrivacyModal && (
                        <div className="privacy-modal">
                            {/* Privacy policy content */}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EditInfoPage;
