import React, { useState, useEffect } from 'react';
import { FaEraser, FaStar } from 'react-icons/fa';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import './MobileNavbar.css';
import { useAuth } from '../context/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

const MobileNavbar = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const { user, logout, loading } = useAuth();
    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        if (isDropdownOpen) setDropdownOpen(false);

        // Toggle body class for scroll prevention
        if (!isMenuOpen) {
            document.body.classList.add('menu-open');
        } else {
            document.body.classList.remove('menu-open');
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    // Cleanup to ensure body scroll is enabled when component unmounts
    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    if (loading) {
        // Optionally, render a loading indicator or placeholder
        return null;
    }

    return (
        <React.Fragment>
            {/* Navbar Header */}
            <header className="navbar-header">
                <div className="navbar-content">
                    <a href="http://www.cleandata.me" className="logo-section">
                        <FaEraser className="logo-icon" />
                        <span className="navbar-title">
                            CleanData<span className="logo-subtitle">.me</span>
                        </span>
                    </a>
                    <button onClick={toggleMenu} className="menu-button">
                        {isMenuOpen ? <FiX className="menu-icon" /> : <FiMenu className="menu-icon" />}
                    </button>
                </div>
            </header>

            {/* Slide-In Menu */}
            <nav className={`slide-menu ${isMenuOpen ? 'open' : ''}`}>
                <ul className="menu-list">
                    <li className="menu-item">
                        <Link to="/" onClick={toggleMenu}>Home</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/pricing" onClick={toggleMenu}>Pricing</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/blog" onClick={toggleMenu}>Blog</Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/contact" onClick={toggleMenu}>Contact</Link>
                    </li>

                    {/* Free Tools Button with Dropdown */}
                    <li className="menu-item">
                        <motion.button 
                            className="free-tools-button"
                            onClick={toggleDropdown}
                            whileTap={{ scale: 0.98 }}
                            whileHover={{ y: -2 }}
                        >
                            Free Tools
                        </motion.button>
                        <AnimatePresence>
                            {isDropdownOpen && (
                                <motion.ul
                                    className="dropdown-menu"
                                    initial={{ opacity: 0, y: -20, scale: 0.95 }}
                                    animate={{ 
                                        opacity: 1, 
                                        y: 0, 
                                        scale: 1,
                                        transition: {
                                            duration: 0.3,
                                            ease: [0.4, 0, 0.2, 1]
                                        }
                                    }}
                                    exit={{ 
                                        opacity: 0, 
                                        y: -20, 
                                        scale: 0.95,
                                        transition: {
                                            duration: 0.2,
                                            ease: [0.4, 0, 1, 1]
                                        }
                                    }}
                                >
                                    {[
                                        { to: "/scamai", text: "RavenAI" },
                                        { to: "/location", text: "Free Broker Scan", className: "data-broker-item" },
                                        { to: "/data-leak", text: "Check Your Email" },
                                        { to: "/password-check", text: "Password Checker" },
                                        { to: "/area-codes", text: "Phone Number Checker" },
                                        { to: "/delete-account", text: "Account Deleter" },
                                        { to: "/password-analyzer", text: "Password Analyzer" },
                                        { to: "/file-scan", text: "Virus Scanner" }
                                    ].map((item, index) => (
                                        <motion.li
                                            key={item.to}
                                            initial={{ opacity: 0, x: -20 }}
                                            animate={{ 
                                                opacity: 1, 
                                                x: 0,
                                                transition: {
                                                    duration: 0.3,
                                                    delay: index * 0.05,
                                                    ease: [0.4, 0, 0.2, 1]
                                                }
                                            }}
                                        >
                                            <Link 
                                                to={item.to} 
                                                onClick={toggleMenu}
                                                className={item.className}
                                            >
                                                {item.text}
                                            </Link>
                                        </motion.li>
                                    ))}
                                </motion.ul>
                            )}
                        </AnimatePresence>
                    </li>

                    <li className="menu-item sign-in">
                        {user ? (
                            <button onClick={handleLogout} className="sign-out-button">
                                Sign Out
                            </button>
                        ) : (
                            <Link to="/login" onClick={toggleMenu}>
                                Sign In
                            </Link>
                        )}
                    </li>
                </ul>
            </nav>
        </React.Fragment>
    );
};

export default MobileNavbar;
