import React from 'react';
import { motion } from 'framer-motion';

const SecurityTools = () => {
    const tools = [
        {
            id: 'data-broker-scan',
            title: 'Data Broker Scanner',
            description: 'Find & remove your personal info from data broker sites',
            icon: '🔍',
            url: 'https://app.cleandata.me/location',
            isPrimary: true
        },
        {
            id: 'data-leak',
            title: 'Data Leak Scanner',
            description: 'Check if your email has been compromised',
            icon: '🚨',
            url: '/data-leak'
        },
        {
            id: 'password-check',
            title: 'Password Strength Checker',
            description: 'Verify your password security',
            icon: '🔒',
            url: '/password-check'
        },
        {
            id: 'security-assessment',
            title: 'Security Assessment',
            description: 'Get a personalized security report',
            icon: '🛡️',
            url: '/security-assessment'
        }
    ];

    return (
        <motion.div 
            className="tools-section"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="tools-header">
                <h3 className="section-header">Recommended Security Tools</h3>
            </div>
            <div className="tools-grid">
                {tools.map((tool, index) => (
                    <motion.a
                        key={tool.id}
                        href={tool.url}
                        className={`tool-card ${tool.isPrimary ? 'primary-tool' : ''}`}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: index * 0.1, duration: 0.3 }}
                        whileHover={{ x: 8, transition: { duration: 0.2 } }}
                        target={tool.url.startsWith('http') ? '_blank' : '_self'}
                        rel={tool.url.startsWith('http') ? 'noopener noreferrer' : ''}
                    >
                        <div className="tool-content">
                            <div className="tool-header">
                                <div className="tool-icon">{tool.icon}</div>
                                <div className="tool-title">{tool.title}</div>
                            </div>
                            <div className="tool-description">{tool.description}</div>
                        </div>
                        <div className="tool-arrow">→</div>
                    </motion.a>
                ))}
            </div>
        </motion.div>
    );
};

export default SecurityTools; 