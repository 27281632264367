import { io } from 'socket.io-client';
import { SOCKET_URL, STORAGE_KEYS } from '../config/api';
import authService from './authService';

class SocketService {
    constructor() {
        this.socket = null;
        this.connected = false;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
        this.baseReconnectDelay = 1000; // 1 second
        this.listeners = new Map();
        this.isInitialized = false;
        this.currentRoom = null;
        this.connectionState = {
            connected: false,
            connecting: false,
            roomJoined: false,
            socketId: null,
            lastError: null,
            timestamp: new Date().toISOString()
        };
    }

    getSocket() {
        return this.socket;
    }

    async initialize() {
        if (this.socket?.connected) {
            console.log('[SocketService] Socket already connected');
            return;
        }

        const token = authService.getToken();
        if (!token) {
            console.error('[SocketService] No token available for socket connection');
            throw new Error('Authentication required for socket connection');
        }

        try {
            this.socket = io(process.env.REACT_APP_SOCKET_URL, {
                auth: { token },
                transports: ['websocket'],
                reconnection: true,
                reconnectionAttempts: this.maxReconnectAttempts,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
            });

            this.setupEventHandlers();

            return new Promise((resolve, reject) => {
                this.socket.on('connect', () => {
                    console.log('[SocketService] Socket connected successfully');
                    this.connected = true;
                    this.reconnectAttempts = 0;
                    this.setupEventHandlers();
                    this.isInitialized = true;
                    this.connectionState = {
                        ...this.connectionState,
                        connected: true,
                        connecting: false,
                        socketId: this.socket.id,
                        timestamp: new Date().toISOString()
                    };
                    this.notifyListeners('connect', { connected: true });
                    resolve();
                });

                this.socket.on('connect_error', (error) => {
                    console.error('[SocketService] Socket connection error:', error);
                    this.reconnectAttempts++;
                    this.connectionState = {
                        ...this.connectionState,
                        connected: false,
                        connecting: false,
                        lastError: error.message,
                        timestamp: new Date().toISOString()
                    };

                    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
                        console.error('[SocketService] Max reconnection attempts reached');
                        reject(new Error('Failed to connect to socket server'));
                    }
                });

                this.socket.on('disconnect', (reason) => {
                    console.log('[SocketService] Socket disconnected:', reason);
                    this.connected = false;
                    this.connectionState = {
                        ...this.connectionState,
                        connected: false,
                        connecting: false,
                        roomJoined: false,
                        lastError: reason,
                        timestamp: new Date().toISOString()
                    };
                    this.notifyListeners('disconnect', { reason });
                });

                this.socket.on('error', (error) => {
                    console.error('[SocketService] Socket error:', error);
                    this.notifyListeners('error', { error: error.message || 'Unknown socket error' });
                    reject(error);
                });

                this.socket.on('reconnect_attempt', (attempt) => {
                    console.log(`[SocketService] Reconnection attempt ${attempt}`);
                    this.reconnectAttempts = attempt;
                });

                this.socket.on('reconnect_failed', () => {
                    console.error('[SocketService] Failed to reconnect after maximum attempts');
                });

                // Connect explicitly
                this.socket.connect();
            });
        } catch (error) {
            console.error('[SocketService] Failed to initialize socket:', error);
            throw error;
        }
    }

    setupEventHandlers() {
        if (!this.socket) return;

        this.socket.on('heartbeat', () => {
            console.log('[SocketService] Heartbeat received');
            this.socket.emit('heartbeat-response');
        });

        this.socket.on('room_joined', (data) => {
            console.log('[SocketService] Room joined:', data);
            this.connectionState = {
                ...this.connectionState,
                roomJoined: true,
                timestamp: new Date().toISOString()
            };
            this.notifyListeners('room_joined', data);
        });

        const simulationEvents = [
            'simulation_progress',
            'simulation_complete',
            'simulation_error',
            'threat_detected'
        ];

        simulationEvents.forEach(event => {
            this.socket.on(event, (data) => {
                console.log(`[SocketService] ${event} event received:`, data);
                this.notifyListeners(event, data);
            });
        });
    }

    connect(token) {
        console.log('[SocketService] Connect called with token:', token ? `${token.substring(0, 10)}...` : 'no token');
        return this.initialize(token);
    }

    async joinRoom(roomId) {
        if (!this.isConnected()) {
            console.error('[SocketService] Cannot join room: socket not connected');
            return false;
        }

        return new Promise((resolve) => {
            if (process.env.NODE_ENV === 'development') {
                console.log('[SocketService] Attempting to join room:', roomId);
            }

            this.socket.emit('join_room', { roomId }, (response) => {
                if (process.env.NODE_ENV === 'development') {
                    console.log('[SocketService] Join room response:', response);
                }
                this.currentRoom = roomId;
                resolve(response?.success ?? false);
            });
        });
    }

    addListener(event, callback) {
        if (!this.listeners.has(event)) {
            this.listeners.set(event, new Set());
        }
        this.listeners.get(event).add(callback);
        console.log(`[SocketService] Added listener for event: ${event}`);
    }

    removeListener(event, callback) {
        if (this.listeners.has(event)) {
            this.listeners.get(event).delete(callback);
            console.log(`[SocketService] Removed listener for event: ${event}`);
        }
    }

    notifyListeners(event, data) {
        if (this.listeners.has(event)) {
            console.log(`[SocketService] Notifying listeners for event: ${event}`, data);
            this.listeners.get(event).forEach(callback => {
                try {
                    callback(data);
                } catch (error) {
                    console.error(`[SocketService] Error in ${event} listener:`, error);
                }
            });
        }
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
            this.connected = false;
            console.log('[SocketService] Socket disconnected');
            this.isInitialized = false;
            this.currentRoom = null;
            this.connectionState = {
                connected: false,
                connecting: false,
                roomJoined: false,
                socketId: null,
                lastError: null,
                timestamp: new Date().toISOString()
            };
        }
    }

    getConnectionState() {
        const state = {
            ...this.connectionState,
            socketId: this.socket?.id,
            socketInstance: !!this.socket,
            initialized: this.isInitialized
        };
        console.log('[SocketService] Connection state:', state);
        return state;
    }

    on(event, callback) {
        if (this.socket) {
            this.socket.on(event, callback);
        }
    }

    off(event, callback) {
        if (this.socket) {
            this.socket.off(event, callback);
        }
    }

    emit(event, data, callback) {
        if (this.socket?.connected) {
            this.socket.emit(event, data, callback);
        } else {
            console.error('[SocketService] Cannot emit event: socket not connected');
            if (callback) {
                callback({ error: 'Socket not connected' });
            }
        }
    }

    isConnected() {
        return this.connected && this.socket?.connected;
    }

    // Method to leave a room
    leaveRoom(roomId) {
        if (!this.isConnected()) {
            console.error('[SocketService] Cannot leave room: socket not connected');
            return;
        }

        this.socket.emit('leave_room', { roomId }, (response) => {
            if (response.success) {
                console.log(`[SocketService] Successfully left room: ${roomId}`);
            } else {
                console.error(`[SocketService] Failed to leave room: ${roomId}`, response.error);
            }
        });
    }
}

const socketService = new SocketService();
export default socketService; 