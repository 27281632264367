import React, { useState, useEffect, useRef, useMemo, useCallback, memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
  Tab,
  Tabs,
  Switch,
  FormControlLabel,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  People as PeopleIcon,
  Security as SecurityIcon,
  Assessment as AssessmentIcon,
  Refresh as RefreshIcon,
  RadioButtonChecked as LiveIcon,
  PlayArrow as PlayIcon,
  Stop as StopIcon,
  Scanner as ScannerIcon,
  Warning as WarningIcon,
  CheckCircle as SuccessIcon,
  Error as ErrorIcon,
  Timeline as TimelineIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import socketService from '../services/socketService';
import UserManagement from '../components/admin/UserManagement';
import ThreatManagement from '../components/admin/ThreatManagement';
import Settings from '../components/admin/Settings';
import AutomatedScans from '../components/admin/AutomatedScans';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import MobileNavbar from '../components/MobileNavbar';
import { API_BASE_URL, REFRESH_INTERVALS } from '../config';
import api from '../services/apiService';
import { useSocket } from '../hooks/useSocket';
import { useAuth } from '../context/AuthContext';
import BulkSimulationControl from '../components/admin/BulkSimulationControl';

const StatCard = ({ title, value, icon: Icon }) => (
  <Card sx={{ 
    height: '100%', 
    backgroundColor: '#1E1E1E',
    border: '1px solid rgba(216, 255, 96, 0.05)',
    boxShadow: 'none',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      border: '1px solid rgba(216, 255, 96, 0.1)',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)',
    }
  }}>
    <CardContent sx={{ 
      display: 'flex', 
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      p: 3
    }}>
      <Box>
        <Typography 
          sx={{ 
            color: 'rgba(255, 255, 255, 0.7)',
            fontSize: '1rem',
            fontWeight: 500,
            mb: 1
          }}
        >
          {title}
        </Typography>
        <Typography 
          variant="h3" 
          sx={{ 
            color: '#fff',
            fontWeight: 600,
            fontSize: '2.5rem'
          }}
        >
          {value}
        </Typography>
      </Box>
      <Icon sx={{ 
        fontSize: 40, 
        color: '#D8FF60',
        opacity: 0.9
      }} />
    </CardContent>
  </Card>
);

const styles = {
  userTable: {
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: '#1e1e1e',
    borderRadius: '8px',
    overflow: 'hidden',
    marginTop: '20px',
    '& th, & td': {
      padding: '12px 16px',
      textAlign: 'left',
      borderBottom: '1px solid #333',
    },
    '& th': {
      backgroundColor: '#2a2a2a',
      fontWeight: 600,
      color: '#fff',
    },
    '& td': {
      color: '#e0e0e0',
    },
  },
  actionButtons: {
    display: 'flex',
    gap: '8px',
    '& button': {
      padding: '6px 12px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      fontSize: '0.875rem',
      transition: 'background-color 0.2s',
      '&.admin': {
        backgroundColor: '#2196f3',
        color: 'white',
        '&:hover': {
          backgroundColor: '#1976d2',
        },
      },
      '&.trial': {
        backgroundColor: '#ff9800',
        color: 'white',
        '&:hover': {
          backgroundColor: '#f57c00',
        },
      },
      '&.premium': {
        backgroundColor: '#4caf50',
        color: 'white',
        '&:hover': {
          backgroundColor: '#388e3c',
        },
      },
      '&.delete': {
        backgroundColor: '#f44336',
        color: 'white',
        '&:hover': {
          backgroundColor: '#d32f2f',
        },
      },
    },
  },
};

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [stats, setStats] = useState({
    totalUsers: 0,
    trialUsers: 0,
    premiumUsers: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [scanMode, setScanMode] = useState('live');
  const [isSimulating, setIsSimulating] = useState(false);
  const [currentScanStatus, setCurrentScanStatus] = useState('');
  const [scanProgress, setScanProgress] = useState(0);
  const [dashboardData, setDashboardData] = useState({
    stats: {
      isScanning: false,
      progress: 0,
      currentSite: '',
      currentStatus: ''
    }
  });
  const [scanStats, setScanStats] = useState({
    activeScanCount: 0,
    queuedScans: 0,
    completedScans: 0,
    errorRate: 0,
    averageDuration: 0
  });
  const [userScans, setUserScans] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [scanDetailsOpen, setScanDetailsOpen] = useState(false);
  const [scanningUsers, setScanningUsers] = useState(new Set());
  const [success, setSuccess] = useState('');
  const { user } = useAuth();

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return {
      'Authorization': token && !token.startsWith('Bearer ') ? `Bearer ${token}` : token,
      'Content-Type': 'application/json'
    };
  };

  const handleAuthError = (error) => {
    console.error('Authentication error:', error);
    if (error.status === 401) {
      localStorage.removeItem('token');
      navigate('/login');
    }
    return null;
  };

  const fetchStats = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/admin/users/stats`, {
        headers: getAuthHeaders(),
        credentials: 'include'
      });

      if (!response.ok) {
        if (response.status === 401) {
          return handleAuthError(response);
        }
        throw new Error('Failed to fetch stats');
      }

      const data = await response.json();
      setStats({
        totalUsers: data.total || 0,
        trialUsers: data.trial || 0,
        premiumUsers: data.active || 0
      });
      setError(null);
    } catch (err) {
      console.error('Error fetching stats:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchScanStats = async () => {
    try {
      const response = await api.get('/admin/threats/stats');
      if (response.data) {
        setScanStats(response.data);
      }
    } catch (error) {
      console.error('Error fetching scan stats:', error);
      setError('Failed to fetch scan statistics');
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get('/admin/users');
      if (response.data) {
        setUserScans(response.data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users');
    }
  };

  const handleUserAction = async (userId, action) => {
    try {
      if (action === 'start') {
        await startScan(userId);
      } else if (action === 'stop') {
        await stopScan(userId);
      }
    } catch (error) {
      console.error('Error handling user action:', error);
      setError(`Failed to ${action} scan`);
    }
  };

  const initializeData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Fetch scan stats
      const statsResponse = await api.get('/admin/threats/stats');
      if (statsResponse.data) {
        setScanStats(statsResponse.data);
      }

      // Fetch users
      const usersResponse = await api.get('/admin/users');
      if (usersResponse.data) {
        setUserScans(usersResponse.data);
      }

      // Fetch scan status for each user
      const scanStatusPromises = usersResponse.data.map(user =>
        api.get(`/admin/scan/status/${user.id}`)
          .then(response => ({
            userId: user.id,
            status: response.data
          }))
          .catch(error => ({
            userId: user.id,
            status: { status: 'error', error: error.message }
          }))
      );

      const scanStatuses = await Promise.all(scanStatusPromises);
      const scanningUserIds = scanStatuses
        .filter(({ status }) => status.status === 'in_progress')
        .map(({ userId }) => userId);

      setScanningUsers(new Set(scanningUserIds));
    } catch (error) {
      console.error('Error initializing data:', error);
      setError('Failed to initialize dashboard data');
    } finally {
      setLoading(false);
    }
  };

  // Initialize socket and fetch initial data
  useEffect(() => {
    const initializeData = async () => {
      try {
        // Connect socket with token
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/login');
          return;
        }

        socketService.connect();
        
        // Fetch initial scan status
        const response = await fetch(`${API_BASE_URL}/api/scan/status`, {
          headers: getAuthHeaders(),
          credentials: 'include'
        });
        
        if (!response.ok) {
          if (response.status === 401) {
            return handleAuthError(response);
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setScanMode(data.mode || 'live');
        setIsSimulating(data.isScanning || false);
        if (data.currentStatus) {
          setCurrentScanStatus(data.currentStatus);
        }
        if (typeof data.progress === 'number') {
          setScanProgress(data.progress);
        }
        setError(null);
      } catch (error) {
        console.error('Error initializing data:', error);
        setError('Failed to initialize dashboard');
      }
    };

    initializeData();
    fetchStats();
    fetchScanStats();
    fetchUsers();

    // Cleanup function
    return () => {
      socketService.disconnect();
    };
  }, [navigate]);

  // Socket event listeners
  useEffect(() => {
    socketService.on('scanStarted', (data) => {
      setIsSimulating(true);
      setScanProgress(0);
      setCurrentScanStatus('Scan started');
    });

    socketService.on('scanStopped', (data) => {
      setIsSimulating(false);
      setCurrentScanStatus('Scan stopped');
    });

    socketService.on('simulation_progress', (data) => {
      setIsSimulating(true);
      if (data.status) {
        setCurrentScanStatus(data.message || data.status);
      }
      if (typeof data.progress === 'number') {
        setScanProgress(data.progress);
      }
    });

    socketService.on('simulation_complete', (data) => {
      setIsSimulating(false);
      setCurrentScanStatus('Scan completed');
      setScanProgress(100);
      fetchStats();
      fetchScanStats();
      fetchUsers();
    });

    // Add listeners for user-specific simulation events
    const handleUserSimulationProgress = (event) => {
      const { userId, progress, currentSite, status } = event.detail;
      setUserScans(prev => prev.map(user => 
        user.id === userId 
          ? { 
              ...user, 
              scanStatus: 'in_progress',
              scanProgress: progress,
              currentSite: currentSite,
              status: status
            }
          : user
      ));
    };

    const handleUserSimulationComplete = (event) => {
      const { userId, results } = event.detail;
      setScanningUsers(prev => {
        const newSet = new Set(prev);
        newSet.delete(userId);
        return newSet;
      });
      setUserScans(prev => prev.map(user => 
        user.id === userId 
          ? { 
              ...user, 
              scanStatus: 'completed',
              scanProgress: 100,
              threatCount: (results?.threats || 0)
            }
          : user
      ));
    };

    window.addEventListener('userSimulationProgress', handleUserSimulationProgress);
    window.addEventListener('userSimulationComplete', handleUserSimulationComplete);

    socketService.on('error', (error) => {
      console.error('Socket error:', error);
      setError(error.message);
    });

    const refreshInterval = setInterval(() => {
      fetchStats();
      fetchScanStats();
      fetchUsers();
    }, 30000);

    return () => {
      socketService.off('scanStarted');
      socketService.off('scanStopped');
      socketService.off('simulation_progress');
      socketService.off('simulation_complete');
      socketService.off('error');
      window.removeEventListener('userSimulationProgress', handleUserSimulationProgress);
      window.removeEventListener('userSimulationComplete', handleUserSimulationComplete);
      clearInterval(refreshInterval);
    };
  }, []);

  // Update the scan mode
  const handleModeChange = async (event) => {
    const newMode = event.target.checked ? 'live' : 'simulate';
    try {
      const response = await fetch(`${API_BASE_URL}/api/scan/mode`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({ mode: newMode })
      });

      if (!response.ok) {
        if (response.status === 401) {
          return handleAuthError(response);
        }
        throw new Error('Failed to update scan mode');
      }

      const data = await response.json();
      setScanMode(data.mode);
      setIsSimulating(data.isScanning);
      setCurrentScanStatus(data.currentStatus);
      setScanProgress(data.progress);
    } catch (error) {
      console.error('Error updating scan mode:', error);
      setError('Failed to update scan mode');
    }
  };

  const startSimulation = () => {
    console.log('Starting simulation...'); // Debug log
    socketService.emit('start_simulation');
    setIsSimulating(true);
  };

  const stopSimulation = () => {
    console.log('Stopping simulation...'); // Debug log
    socketService.emit('stop_simulation');
    setIsSimulating(false);
  };

  const handleRefresh = () => {
    setLoading(true);
    fetchStats();
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const formatJoinedDate = (dateString) => {
    try {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'N/A';
      
      const now = new Date();
      const diffTime = Math.abs(now - date);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      if (diffDays < 1) return 'Today';
      if (diffDays === 1) return 'Yesterday';
      if (diffDays < 7) return `${diffDays} days ago`;
      
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'N/A';
    }
  };

  const simulateScan = async (userId) => {
    try {
      setLoading(true);
      
      // First, join the user's room for receiving updates
      await socketService.joinRoom(userId);
      
      // Start the user simulation
      await socketService.startUserSimulation(userId);

      // Add user to scanning set
      setScanningUsers(prev => new Set([...prev, userId]));
      
      // Show success message
      setSuccess('Simulation started successfully');
      
      // Update user's scan status in the list
      setUserScans(prev => prev.map(user => 
        user.id === userId 
          ? { ...user, scanStatus: 'in_progress', scanProgress: 0 }
          : user
      ));

    } catch (error) {
      console.error('Error starting simulation:', error);
      setError('Failed to start simulation');
      // Remove user from scanning set if there was an error
      setScanningUsers(prev => {
        const newSet = new Set(prev);
        newSet.delete(userId);
        return newSet;
      });
    } finally {
      setLoading(false);
    }
  };

  // Start scan for user
  const startScan = async (userId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/scan/${userId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) throw new Error('Failed to start scan');
      
      setScanningUsers(prev => new Set([...prev, userId]));
      fetchUsers(); // Refresh user list
    } catch (error) {
      console.error('Error starting scan:', error);
    }
  };

  // Stop scan for user
  const stopScan = async (userId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/scan/stop/${userId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      
      if (!response.ok) throw new Error('Failed to stop scan');
      
      setScanningUsers(prev => {
        const newSet = new Set(prev);
        newSet.delete(userId);
        return newSet;
      });
      fetchUsers(); // Refresh user list
    } catch (error) {
      console.error('Error stopping scan:', error);
    }
  };

  // Socket event handlers
  useEffect(() => {
    if (socketService) {
      socketService.on('scan_progress', (data) => {
        // Update scan progress for specific user
        setUserScans(prev => prev.map(user => 
          user.id === data.userId 
            ? { ...user, scanProgress: data.progress, currentStatus: data.status }
            : user
        ));
      });

      socketService.on('scan_complete', (data) => {
        // Update scan completion status
        setScanningUsers(prev => {
          const newSet = new Set(prev);
          newSet.delete(data.userId);
          return newSet;
        });
        fetchUsers(); // Refresh user list
      });

      socketService.on('scan_error', (data) => {
        // Handle scan errors
        console.error('Scan error:', data);
        setScanningUsers(prev => {
          const newSet = new Set(prev);
          newSet.delete(data.userId);
          return newSet;
        });
        fetchUsers(); // Refresh user list
      });

      socketService.on('threat_found', (data) => {
        // Update threat count for specific user
        setUserScans(prev => prev.map(user => 
          user.id === data.userId 
            ? { ...user, threatCount: (user.threatCount || 0) + 1 }
            : user
        ));
      });

      return () => {
        socketService.off('scan_progress');
        socketService.off('scan_complete');
        socketService.off('scan_error');
        socketService.off('threat_found');
      };
    }
  }, [socketService]);

  // Initial data fetch
  useEffect(() => {
    fetchScanStats();
    fetchUsers();
    const refreshInterval = setInterval(() => {
      fetchScanStats();
      fetchUsers();
    }, 30000); // Refresh every 30 seconds

    return () => clearInterval(refreshInterval);
  }, []);

  const getScanStatusColor = (status) => {
    switch (status) {
      case 'completed': return 'success';
      case 'in_progress': return 'warning';
      case 'error': return 'error';
      default: return 'default';
    }
  };

  const formatDuration = (minutes) => {
    if (minutes < 60) return `${minutes}m`;
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  // Add new tab for Scan Management
  const renderScanManagement = () => (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Active Scans"
            value={scanStats.activeScanCount}
            icon={ScannerIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Queued Scans"
            value={scanStats.queuedScans}
            icon={TimelineIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Completed Today"
            value={scanStats.completedScans}
            icon={SuccessIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Error Rate"
            value={`${scanStats.errorRate}%`}
            icon={WarningIcon}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ 
        backgroundColor: '#1E1E1E',
        border: '1px solid rgba(216, 255, 96, 0.1)',
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Last Scan</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Threats Found</TableCell>
              <TableCell>Progress</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userScans.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {user.lastScanTime ? new Date(user.lastScanTime).toLocaleString() : 'Never'}
                </TableCell>
                <TableCell>
                  <Chip
                    label={user.scanStatus}
                    color={getScanStatusColor(user.scanStatus)}
                    size="small"
                  />
                </TableCell>
                <TableCell>{user.threatCount || 0}</TableCell>
                <TableCell>
                  {scanningUsers.has(user.id) ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <CircularProgress size={20} />
                      <Typography variant="body2">
                        {user.scanProgress || 0}%
                      </Typography>
                    </Box>
                  ) : '-'}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {scanningUsers.has(user.id) ? (
                      <Tooltip title="Stop Scan">
                        <IconButton
                          onClick={() => stopScan(user.id)}
                          size="small"
                          color="error"
                        >
                          <StopIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Start Scan">
                        <IconButton
                          onClick={() => startScan(user.id)}
                          size="small"
                          color="success"
                        >
                          <PlayIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="View Details">
                      <IconButton
                        onClick={() => {
                          setSelectedUser(user);
                          setScanDetailsOpen(true);
                        }}
                        size="small"
                      >
                        <AssessmentIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={scanDetailsOpen}
        onClose={() => setScanDetailsOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Scan Details - {selectedUser?.email}
        </DialogTitle>
        <DialogContent>
          {selectedUser && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">Scan History</Typography>
              {/* Add scan history details here */}
              
              <Typography variant="h6" sx={{ mt: 3 }}>
                Threat Summary
              </Typography>
              {/* Add threat summary here */}
              
              <Typography variant="h6" sx={{ mt: 3 }}>
                Data Broker Status
              </Typography>
              {/* Add data broker status here */}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setScanDetailsOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

  // Update renderUserTable to use the new styles
  const renderUserTable = () => {
    return (
      <TableContainer component={Paper} sx={{ 
        backgroundColor: '#1E1E1E',
        border: '1px solid rgba(216, 255, 96, 0.05)',
        boxShadow: 'none',
        borderRadius: '8px',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 300px)',
        '& .MuiTable-root': {
          minWidth: 750,
        }
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ 
                color: '#fff', 
                backgroundColor: '#252525',
                fontWeight: 600,
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}>Email</TableCell>
              <TableCell sx={{ 
                color: '#fff', 
                backgroundColor: '#252525',
                fontWeight: 600,
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}>Role</TableCell>
              <TableCell sx={{ 
                color: '#fff', 
                backgroundColor: '#252525',
                fontWeight: 600,
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}>Subscription</TableCell>
              <TableCell sx={{ 
                color: '#fff', 
                backgroundColor: '#252525',
                fontWeight: 600,
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}>Joined</TableCell>
              <TableCell sx={{ 
                color: '#fff', 
                backgroundColor: '#252525',
                fontWeight: 600,
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userScans.map(user => (
              <TableRow key={user.id} sx={{
                '&:hover': {
                  backgroundColor: 'rgba(216, 255, 96, 0.05)'
                }
              }}>
                <TableCell sx={{ 
                  color: '#fff',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                }}>{user.email}</TableCell>
                <TableCell sx={{ 
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                  <Chip
                    label={user.role}
                    size="small"
                    sx={{
                      backgroundColor: user.role === 'admin' ? 'rgba(216, 255, 96, 0.1)' : 'rgba(255, 255, 255, 0.1)',
                      color: user.role === 'admin' ? '#D8FF60' : '#fff',
                      fontWeight: 500,
                      fontSize: '0.75rem'
                    }}
                  />
                </TableCell>
                <TableCell sx={{ 
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                  <Chip
                    label={user.subscription_status || 'trial'}
                    size="small"
                    sx={{
                      backgroundColor: 
                        user.subscription_status === 'premium' ? 'rgba(216, 255, 96, 0.1)' :
                        user.subscription_status === 'trial' ? 'rgba(255, 152, 0, 0.1)' :
                        'rgba(255, 67, 67, 0.1)',
                      color: 
                        user.subscription_status === 'premium' ? '#D8FF60' :
                        user.subscription_status === 'trial' ? '#ff9800' :
                        '#ff4343',
                      fontWeight: 500,
                      fontSize: '0.75rem'
                    }}
                  />
                </TableCell>
                <TableCell sx={{ 
                  color: 'rgba(255, 255, 255, 0.7)',
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                  fontSize: '0.875rem'
                }}>
                  {formatJoinedDate(user.created_at || user.joined_at)}
                </TableCell>
                <TableCell sx={{ 
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {user.role !== 'admin' && (
                      <>
                        <Tooltip title="Make Admin">
                          <IconButton
                            size="small"
                            onClick={() => handleUserAction(user.id, 'makeAdmin')}
                            sx={{
                              backgroundColor: 'rgba(216, 255, 96, 0.1)',
                              color: '#D8FF60',
                              '&:hover': {
                                backgroundColor: 'rgba(216, 255, 96, 0.2)'
                              }
                            }}
                          >
                            <SecurityIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Run Simulation">
                          <IconButton
                            size="small"
                            onClick={() => simulateScan(user.id)}
                            sx={{
                              backgroundColor: 'rgba(255, 255, 255, 0.1)',
                              color: '#fff',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)'
                              }
                            }}
                          >
                            <PlayIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete User">
                          <IconButton
                            size="small"
                            onClick={() => handleUserAction(user.id, 'delete')}
                            sx={{
                              backgroundColor: 'rgba(255, 67, 67, 0.1)',
                              color: '#ff4343',
                              '&:hover': {
                                backgroundColor: 'rgba(255, 67, 67, 0.2)'
                              }
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return <UserManagement />;
      case 1:
        return <AutomatedScans />;
      case 2:
        return <ThreatManagement />;
      case 3:
        return <Settings />;
      default:
        return <UserManagement />;
    }
  };

  if (!user?.isAdmin) {
    return (
      <div className="p-4">
        <h1 className="text-2xl font-bold text-red-600">Access Denied</h1>
        <p className="mt-2">You do not have permission to access this page.</p>
      </div>
    );
  }

  return (
    <div className="app-layout">
      <Sidebar />
      <div className="main-content">
        <Navbar />
        <MobileNavbar />
        <Box sx={{ 
          backgroundColor: '#121212',
          minHeight: '100vh',
          height: '100%',
          overflow: 'auto',
          position: 'relative',
          color: '#fff',
          paddingBottom: '2rem'
        }}>
          <Container maxWidth={false} sx={{ 
            pt: 2,
            height: '100%',
            maxWidth: '1800px'
          }}>
            <Box 
              display="flex" 
              justifyContent="space-between" 
              alignItems="center" 
              sx={{
                mb: 3,
                backgroundColor: '#1E1E1E',
                borderRadius: '8px',
                padding: '12px 20px',
                border: '1px solid rgba(255, 255, 255, 0.05)'
              }}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <Typography 
                  variant="h4" 
                  sx={{ 
                    color: '#fff',
                    fontSize: '1.5rem',
                    fontWeight: 600,
                    m: 0
                  }}
                >
                  Admin Dashboard
                </Typography>
                {isSimulating && (
                  <Chip
                    icon={<LiveIcon sx={{ color: '#D8FF60 !important' }} />}
                    label={currentScanStatus || 'Scanning...'}
                    sx={{
                      backgroundColor: 'rgba(216, 255, 96, 0.1)',
                      color: '#D8FF60',
                      border: '1px solid rgba(216, 255, 96, 0.2)',
                      height: '28px'
                    }}
                  />
                )}
              </Box>
              <Box 
                display="flex" 
                alignItems="center" 
                gap={2}
                sx={{
                  backgroundColor: '#252525',
                  borderRadius: '6px',
                  padding: '4px 8px'
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={scanMode === 'simulation'}
                      onChange={handleModeChange}
                      disabled={isSimulating}
                      sx={{
                        width: 34,
                        height: 20,
                        padding: 0,
                        '& .MuiSwitch-switchBase': {
                          padding: 0,
                          margin: '2px',
                          transitionDuration: '300ms',
                          '&.Mui-checked': {
                            transform: 'translateX(14px)',
                            color: '#fff',
                            '& + .MuiSwitch-track': {
                              backgroundColor: '#D8FF60',
                              opacity: 1,
                              border: 0,
                            },
                          },
                        },
                        '& .MuiSwitch-thumb': {
                          boxSizing: 'border-box',
                          width: 16,
                          height: 16,
                          backgroundColor: '#fff',
                        },
                        '& .MuiSwitch-track': {
                          borderRadius: 26 / 2,
                          backgroundColor: '#39393D',
                          opacity: 1,
                        }
                      }}
                    />
                  }
                  label={
                    <Typography 
                      sx={{ 
                        color: '#fff',
                        fontSize: '0.813rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px'
                      }}
                    >
                      {scanMode === 'simulation' ? 'Simulation Mode' : 'Live Mode'}
                      <RefreshIcon 
                        sx={{ 
                          fontSize: 14,
                          color: '#D8FF60',
                          animation: isSimulating ? 'spin 2s linear infinite' : 'none',
                          '@keyframes spin': {
                            '0%': { transform: 'rotate(0deg)' },
                            '100%': { transform: 'rotate(360deg)' }
                          }
                        }} 
                      />
                    </Typography>
                  }
                  sx={{ 
                    margin: 0,
                    '& .MuiFormControlLabel-label': {
                      marginLeft: '6px'
                    }
                  }}
                />
                {scanMode === 'simulation' && (
                  <Button
                    onClick={isSimulating ? stopSimulation : startSimulation}
                    variant="contained"
                    size="small"
                    startIcon={isSimulating ? <StopIcon /> : <PlayIcon />}
                    sx={{
                      backgroundColor: isSimulating ? '#ff4343' : '#D8FF60',
                      color: '#000',
                      fontSize: '0.813rem',
                      padding: '4px 12px',
                      minWidth: 'auto',
                      height: '28px',
                      '&:hover': {
                        backgroundColor: isSimulating ? '#ff2020' : '#b3ff00',
                      }
                    }}
                  >
                    {isSimulating ? 'Stop' : 'Start'}
                  </Button>
                )}
                <IconButton 
                  onClick={handleRefresh} 
                  disabled={loading}
                  size="small"
                  sx={{ 
                    color: '#D8FF60',
                    backgroundColor: 'rgba(216, 255, 96, 0.1)',
                    width: '28px',
                    height: '28px',
                    '&:hover': {
                      backgroundColor: 'rgba(216, 255, 96, 0.2)'
                    }
                  }}
                >
                  <RefreshIcon sx={{ fontSize: 16 }} />
                </IconButton>
              </Box>
            </Box>

            <Grid container spacing={3} mb={4}>
              <Grid item xs={12} sm={6} md={4}>
                <StatCard
                  title="Total Users"
                  value={stats.totalUsers}
                  icon={PeopleIcon}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <StatCard
                  title="Trial Users"
                  value={stats.trialUsers}
                  icon={PeopleIcon}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <StatCard
                  title="Premium Users"
                  value={stats.premiumUsers}
                  icon={PeopleIcon}
                />
              </Grid>
            </Grid>

            <Box sx={{ mb: 4 }}>
              <Tabs 
                value={activeTab} 
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                  '& .MuiTab-root': {
                    color: 'rgba(255, 255, 255, 0.7)',
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    textTransform: 'none',
                    minWidth: 'auto',
                    padding: '12px 16px',
                    '&.Mui-selected': {
                      color: '#D8FF60',
                    },
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#D8FF60',
                    height: 2,
                  },
                }}
              >
                <Tab label="Users" />
                <Tab label="Scan Management" />
                <Tab label="Automated Scans" />
                <Tab label="Threats" />
                <Tab label="Settings" />
              </Tabs>
            </Box>

            {renderTabContent()}

            {error && (
              <Box
                sx={{ 
                  mt: 2,
                  p: 2,
                  backgroundColor: 'rgba(255, 67, 67, 0.1)',
                  border: '1px solid rgba(255, 67, 67, 0.3)',
                  borderRadius: 1,
                }}
              >
                <Typography 
                  sx={{ 
                    color: '#ff4343',
                    fontSize: '0.9rem'
                  }}
                >
                  {error}
                </Typography>
              </Box>
            )}
          </Container>
        </Box>
      </div>
    </div>
  );
};

export default AdminDashboard; 