import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';

const WelcomeMessage = forwardRef(({ preMadeQuestions, onQuestionClick }, ref) => {
    return (
        <motion.div 
            ref={ref}
            className="welcome-message"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.4 }}
        >
            <motion.h1 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.4 }}
            >
                Scam AI - Protect Your Data & Prevent Scams
            </motion.h1>
            <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.4 }}
            >
                Greetings! I am Raven, your advanced cybersecurity & data privacy AI. 
                I can help if you've been approached at your doorstep for overpriced work, 
                or if you're worried about phone/email scams or data leaks.
            </motion.p>
            <motion.div 
                className="pre-made-questions"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.4 }}
            >
                {preMadeQuestions.map((question, idx) => (
                    <motion.button
                        key={idx}
                        onClick={() => onQuestionClick(question.text)}
                        className="question-button"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ 
                            opacity: 1, 
                            x: 0,
                            transition: {
                                delay: 0.6 + (idx * 0.1),
                                duration: 0.3
                            }
                        }}
                        whileHover={{ x: 5, transition: { duration: 0.2 } }}
                    >
                        <span className="question-icon">{question.icon}</span>
                        <span className="question-text">{question.text}</span>
                    </motion.button>
                ))}
            </motion.div>
        </motion.div>
    );
});

WelcomeMessage.displayName = 'WelcomeMessage';

export default WelcomeMessage; 