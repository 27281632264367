import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaEraser, FaLock, FaShieldVirus, FaSearchLocation, FaEnvelope, FaSignOutAlt, FaRobot, FaTrash, FaStar } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import './Navbar.css';
import { useAuth } from '../context/AuthContext';
import useWindowSize from '../hooks/useWindowSize';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [hoveredLink, setHoveredLink] = useState(null);
    const dropdownRef = useRef(null);
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const { user, logout } = useAuth();

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        if (isOpen) setDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const dropdownItems = [
        { name: "RavenAI", link: '/scamai', icon: FaRobot },
        { name: "Free Broker Scan", link: '/location', icon: FaSearchLocation },
        { name: "Check Your Email", link: '/data-leak', icon: FaEnvelope },
        { name: "Password Checker", link: '/password-check', icon: FaLock },
        { name: "Phone Number Checker", link: '/area-codes', icon: FaSearchLocation },
        { name: "Account Deleter", link: '/delete-account', icon: FaTrash },
        { name: "Password Analyzer", link: '/password-analyzer', icon: FaLock },
        { name: "Virus Scanner", link: '/file-scan', icon: FaShieldVirus }
    ];

    if (width <= 768) return null;

    return (
        <header className="navbar">
            <div className="navbar-content">
                <Link to="/" className="logo-container">
                    <div className="logo-wrapper">
                        <FaEraser className="eraser-icon" />
                        <div className="text-wrapper">
                            <span className="logo-text">CleanData</span>
                            <span className="logo-subtitle">.me</span>
                        </div>
                    </div>
                </Link>

                <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
                    <a href="https://cleandata.me" className="nav-link">Home</a>
                    <a href="https://cleandata.me/pricing" className="nav-link">Pricing</a>
                    <a href="https://cleandata.me/blog" className="nav-link">Blog</a>
                    <a href="https://cleandata.me/contact" className="nav-link">Contact</a>

                    <div className="dropdown-container" ref={dropdownRef}>
                        <motion.button
                            className="dropdown-toggle"
                            onClick={toggleDropdown}
                            onMouseEnter={() => setHoveredLink('freeTools')}
                            onMouseLeave={() => setHoveredLink(null)}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            Free Tools {isDropdownOpen ? <FiChevronUp /> : <FiChevronDown />}
                        </motion.button>

                        <AnimatePresence>
                            {isDropdownOpen && (
                                <motion.ul
                                    className="dropdown"
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -10 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    {dropdownItems.map((item) => (
                                        <motion.li key={item.name}>
                                            <motion.button
                                                onClick={() => {
                                                    navigate(item.link);
                                                    setDropdownOpen(false);
                                                }}
                                                whileHover={{ scale: 1.02 }}
                                                whileTap={{ scale: 0.95 }}
                                            >
                                                <item.icon />
                                                <span>{item.name}</span>
                                            </motion.button>
                                        </motion.li>
                                    ))}
                                </motion.ul>
                            )}
                        </AnimatePresence>
                    </div>

                    {user ? (
                        <motion.button
                            onClick={handleLogout}
                            className="logout-btn"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <span>Sign Out</span>
                            <FaSignOutAlt />
                        </motion.button>
                    ) : (
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Link to="/login" className="login-btn">
                                Sign In
                            </Link>
                        </motion.div>
                    )}
                </nav>
            </div>
        </header>
    );
};

export default Navbar;
