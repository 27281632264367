import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTachometerAlt,
    faUserEdit,
    faExchangeAlt,
    faListAlt,
    faLifeRing,
    faPlus,
    faSignal,
    faShieldAlt,
    faUsers,
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import profileImage from '../assets/profile.jpg';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Sidebar = () => {
    const location = useLocation();
    const { user, isAdmin } = useAuth();
    
    const getDisplayName = () => {
        if (!user) {
            return 'Welcome Back!';
        }

        // Check for both camelCase and snake_case formats
        const firstName = user.firstName || user.first_name;
        const lastName = user.lastName || user.last_name;

        if (firstName && lastName) {
            return `${firstName} ${lastName}`.trim();
        }

        // If no name is found, use email username
        if (user.email) {
            return user.email.split('@')[0];
        }

        return 'Welcome Back!';
    };

    const calculateSubscriptionStatus = () => {
        if (!user) return 'protected';
        
        // Use the actual subscription status from the API
        const status = user.subscriptionStatus || 'protected';
        
        // Map API status to display status if needed
        const statusMap = {
            'active': 'protected',
            'trial': 'trial',
            'expired': 'expired',
            'cancelled': 'cancelled'
        };
        
        return statusMap[status.toLowerCase()] || status;
    };

    const getMemberSince = () => {
        if (!user) return 'Not available';
        return user.memberSince || 'Not available';
    };

    const subscriptionStatus = calculateSubscriptionStatus();
    const memberSince = getMemberSince();

    return (
        <div className="sidebar">
            <div className="sidebar-profile">
                <div className="profile-pic-wrapper">
                    <img src={profileImage} alt="User" className="profile-pic" />
                    <div className="online-status">
                        <div className="status-dot"></div>
                    </div>
                </div>
                <div className="profile-info">
                    <h3 className="user-name">{getDisplayName()}</h3>
                    <div className="status-badge">
                        <span className="status-icon"></span>
                        {subscriptionStatus.charAt(0).toUpperCase() + subscriptionStatus.slice(1)}
                    </div>
                    <div className="member-since">
                        Member since {memberSince}
                    </div>
                </div>
            </div>
            <nav className="sidebar-nav">
                <Link to="/dashboard" className={`nav-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faTachometerAlt} className="nav-icon" />
                    <span>Dashboard</span>
                </Link>
                <Link to="/data-removals" className={`nav-item ${location.pathname === '/data-removals' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faSignal} className="nav-icon" />
                    <span>Live Reports</span>
                    {location.pathname !== '/data-removals' && (
                        <div className="status-indicator">
                            <div className="status-dot" />
                            <span>LIVE</span>
                        </div>
                    )}
                </Link>
                <Link to="/edit-info" className={`nav-item ${location.pathname === '/edit-info' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faUserEdit} className="nav-icon" />
                    <span>Edit Your Info</span>
                </Link>
                <Link to="/change-plan" className={`nav-item ${location.pathname === '/change-plan' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faExchangeAlt} className="nav-icon" />
                    <span>Change Plan</span>
                </Link>
                <Link to="/sites" className={`nav-item ${location.pathname === '/sites' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faListAlt} className="nav-icon" />
                    <span>Sites We Cover</span>
                </Link>
                <Link to="/support" className={`nav-item ${location.pathname === '/support' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faLifeRing} className="nav-icon" />
                    <span>Support</span>
                </Link>

                {isAdmin && (
                    <>
                        <div className="nav-section-divider">
                            <span>ADMIN PANEL</span>
                        </div>
                        <Link to="/admin" className={`nav-item admin ${location.pathname === '/admin' ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faShieldAlt} className="nav-icon" />
                            <span>Admin Dashboard</span>
                        </Link>
                        <Link to="/admin/users" className={`nav-item admin ${location.pathname === '/admin/users' ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faUsers} className="nav-icon" />
                            <span>User Management</span>
                        </Link>
                        <Link to="/admin/threats" className={`nav-item admin ${location.pathname === '/admin/threats' ? 'active' : ''}`}>
                            <FontAwesomeIcon icon={faExclamationTriangle} className="nav-icon" />
                            <span>Threat Management</span>
                        </Link>
                    </>
                )}
            </nav>
            <Link 
                to="/change-plan" 
                className="add-family-member-button"
            >
                <FontAwesomeIcon icon={faPlus} className="button-icon" />
                <span>Add Family</span>
            </Link>
        </div>
    );
};

export default Sidebar;