import { useState, useEffect, useCallback, useRef } from 'react';
import io from 'socket.io-client';
import { getToken } from '../services/authService';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'https://cleandata-api-25456108afa7.herokuapp.com';
const HEARTBEAT_INTERVAL = 30000; // 30 seconds
const MAX_RECONNECTION_ATTEMPTS = 5;
const RECONNECTION_DELAY = 1000;

export const useSocket = (userId) => {
    const [connected, setConnected] = useState(false);
    const [roomJoined, setRoomJoined] = useState(false);
    const [error, setError] = useState(null);
    const [isSimulating, setIsSimulating] = useState(false);
    const [simulationProgress, setSimulationProgress] = useState(0);
    const [currentSite, setCurrentSite] = useState(null);
    const [currentStage, setCurrentStage] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');
    const [metrics, setMetrics] = useState({
        sitesScanned: 0,
        potentialThreats: 0,
        totalMatches: 0
    });

    const socketRef = useRef(null);
    const reconnectAttemptsRef = useRef(0);
    const cleanupInProgressRef = useRef(false);
    const heartbeatIntervalRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);

    const startHeartbeat = useCallback(() => {
        if (heartbeatIntervalRef.current) {
            clearInterval(heartbeatIntervalRef.current);
        }
        heartbeatIntervalRef.current = setInterval(() => {
            if (socketRef.current?.connected) {
                socketRef.current.emit('ping');
                console.log('[Socket] Sending heartbeat ping');
            }
        }, HEARTBEAT_INTERVAL);
    }, []);

    const stopHeartbeat = useCallback(() => {
        if (heartbeatIntervalRef.current) {
            clearInterval(heartbeatIntervalRef.current);
            heartbeatIntervalRef.current = null;
        }
    }, []);

    const cleanup = useCallback(() => {
        if (cleanupInProgressRef.current) return;
        cleanupInProgressRef.current = true;

        console.log('[Socket] Cleaning up socket connection');
        stopHeartbeat();
        
        if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
            reconnectTimeoutRef.current = null;
        }
        
        if (socketRef.current) {
            socketRef.current.disconnect();
            socketRef.current = null;
        }

        setConnected(false);
        setRoomJoined(false);
        setError(null);
        setIsSimulating(false);
        setSimulationProgress(0);
        setCurrentSite(null);
        setCurrentStage(null);
        setStatusMessage('');
        setMetrics({
            sitesScanned: 0,
            potentialThreats: 0,
            totalMatches: 0
        });
        
        cleanupInProgressRef.current = false;
    }, [stopHeartbeat]);

    const connect = useCallback(() => {
        if (!userId) {
            console.log('[Socket] No user ID provided, skipping connection');
            return;
        }

        const token = getToken();
        if (!token) {
            console.error('[Socket] No token available');
            setError('Authentication required');
            return;
        }

        cleanup();

        try {
            console.log('[Socket] Creating new socket connection');
            socketRef.current = io(SOCKET_URL, {
                transports: ['websocket'],
                autoConnect: false,
                reconnection: true,
                reconnectionAttempts: MAX_RECONNECTION_ATTEMPTS,
                reconnectionDelay: RECONNECTION_DELAY,
                reconnectionDelayMax: 5000,
                timeout: 45000,
                forceNew: true,
                auth: { 
                    token: token
                },
                extraHeaders: {
                    'Authorization': `Bearer ${token}`,
                    'Origin': window.location.origin
                }
            });

            socketRef.current.on('connect', () => {
                console.log('[Socket] Connected successfully', socketRef.current.id);
                setConnected(true);
                setError(null);
                reconnectAttemptsRef.current = 0;
                startHeartbeat();
                
                // Join user's room
                console.log('[Socket] Joining room for user:', userId);
                socketRef.current.emit('join', userId);
            });

            socketRef.current.on('room_joined', (data) => {
                console.log('[Socket] Room joined successfully', data);
                setRoomJoined(true);

                // Check for interrupted scan
                socketRef.current.emit('check_scan_status');
            });

            socketRef.current.on('scan_status', (data) => {
                console.log('[Socket] Received scan status:', data);
                if (data.isScanning || data.is_scanning) {
                    setIsSimulating(true);
                    setSimulationProgress(data.progress || 0);
                    setCurrentSite(data.currentSite || data.current_site);
                    setCurrentStage(data.currentStage || data.current_stage);
                    setStatusMessage(data.message || data.status || 'Resuming scan...');
                    if (data.metrics) {
                        setMetrics({
                            sitesScanned: data.metrics.sitesScanned || 0,
                            potentialThreats: data.metrics.potentialThreats || 0,
                            totalMatches: data.metrics.totalMatches || 0
                        });
                    }
                } else {
                    // Reset state when not scanning
                    setIsSimulating(false);
                    setSimulationProgress(0);
                    setCurrentSite(null);
                    setCurrentStage(null);
                    setStatusMessage(data.message || data.status || 'Ready to scan');
                    if (data.metrics) {
                        setMetrics({
                            sitesScanned: data.metrics.sitesScanned || 0,
                            potentialThreats: data.metrics.potentialThreats || 0,
                            totalMatches: data.metrics.totalMatches || 0
                        });
                    }
                }
            });

            socketRef.current.on('disconnect', (reason) => {
                console.log('[Socket] Disconnected:', reason);
                setConnected(false);
                setRoomJoined(false);
                stopHeartbeat();

                // Attempt to reconnect unless it was an intentional disconnect
                if (reason === 'io server disconnect' || reason === 'transport close') {
                    console.log('[Socket] Attempting to reconnect...');
                    if (reconnectTimeoutRef.current) {
                        clearTimeout(reconnectTimeoutRef.current);
                    }
                    reconnectTimeoutRef.current = setTimeout(() => {
                        if (socketRef.current) {
                            socketRef.current.connect();
                        }
                    }, RECONNECTION_DELAY);
                }
            });

            socketRef.current.on('connect_error', (error) => {
                console.error('[Socket] Connection error:', {
                    message: error.message,
                    description: error.description,
                    data: error.data,
                    type: error.type,
                    tokenLength: token ? token.length : 0,
                    tokenPrefix: token ? token.substring(0, 10) + '...' : 'no token'
                });
                setError(error.message);
                setConnected(false);
                stopHeartbeat();
                
                if (reconnectAttemptsRef.current >= MAX_RECONNECTION_ATTEMPTS) {
                    console.log('[Socket] Max reconnection attempts reached');
                    socketRef.current.disconnect();
                    setError('Failed to connect after multiple attempts. Please refresh the page.');
                } else {
                    reconnectAttemptsRef.current += 1;
                    console.log(`[Socket] Reconnection attempt ${reconnectAttemptsRef.current} of ${MAX_RECONNECTION_ATTEMPTS}`);
                    if (reconnectTimeoutRef.current) {
                        clearTimeout(reconnectTimeoutRef.current);
                    }
                    const delay = Math.min(1000 * Math.pow(2, reconnectAttemptsRef.current), 10000);
                    console.log(`[Socket] Waiting ${delay}ms before next reconnection attempt`);
                    reconnectTimeoutRef.current = setTimeout(() => {
                        if (token) {
                            console.log('[Socket] Attempting to reconnect...');
                            socketRef.current.connect();
                        } else {
                            console.error('[Socket] No token available for reconnection');
                            setError('Authentication required');
                        }
                    }, delay);
                }
            });

            socketRef.current.on('error', (error) => {
                console.error('[Socket] Socket error:', error);
                setError(error.message || 'An error occurred with the socket connection');
            });

            socketRef.current.on('room_error', (error) => {
                console.error('[Socket] Room error:', error);
                setError(error.message || 'Failed to join room');
                setRoomJoined(false);
            });

            socketRef.current.on('simulation_progress', (data) => {
                console.log('[Socket] Simulation progress:', data);
                setIsSimulating(true);
                setSimulationProgress(data.progress || 0);
                setCurrentSite(data.currentSite || data.current_site);
                setCurrentStage(data.currentStage || data.current_stage);
                setStatusMessage(data.message || data.status || '');
                
                if (data.metrics) {
                    setMetrics({
                        sitesScanned: data.metrics.sitesScanned || 0,
                        potentialThreats: data.metrics.potentialThreats || 0,
                        totalMatches: data.metrics.totalMatches || 0
                    });
                }

                // Check if scan is complete
                if (data.status === 'completed' || data.progress === 100) {
                    setIsSimulating(false);
                    setSimulationProgress(100);
                    setCurrentSite(null);
                    setCurrentStage(null);
                    setStatusMessage('Scan completed successfully');
                }
            });

            socketRef.current.on('simulation_complete', (data) => {
                console.log('[Socket] Simulation complete:', data);
                setIsSimulating(false);
                setSimulationProgress(100);
                setCurrentSite(null);
                setCurrentStage(null);
                setStatusMessage(data.message || 'Scan completed successfully');
                if (data.metrics) {
                    setMetrics({
                        sitesScanned: data.metrics.sitesScanned || 0,
                        potentialThreats: data.metrics.potentialThreats || 0,
                        totalMatches: data.metrics.totalMatches || 0
                    });
                }
            });

            socketRef.current.on('simulation_error', (error) => {
                console.error('[Socket] Simulation error:', error);
                setError(error.message);
                // Only reset simulation state if error is not recoverable
                if (!error.recoverable) {
                    setIsSimulating(false);
                    setSimulationProgress(0);
                    setCurrentSite(null);
                    setCurrentStage(null);
                }
                setStatusMessage(error.message || 'An error occurred during the scan');
            });

            socketRef.current.connect();

        } catch (error) {
            console.error('[Socket] Error initializing socket:', error);
            setError('Failed to connect to server');
            setConnected(false);
            stopHeartbeat();
        }
    }, [userId, cleanup, startHeartbeat, stopHeartbeat]);

    const startSimulation = useCallback(() => {
        if (!socketRef.current?.connected) {
            console.error('[Socket] Cannot start simulation: not connected');
            setError('Not connected to server');
            return;
        }
        if (!roomJoined) {
            console.error('[Socket] Cannot start simulation: not joined to room');
            setError('Not joined to room');
            return;
        }
        console.log('[Socket] Starting simulation');
        
        // Reset state before starting
        setError(null);
        setIsSimulating(true);
        setSimulationProgress(0);
        setCurrentSite(null);
        setCurrentStage('initializing');
        setStatusMessage('Starting scan...');
        setMetrics({
            sitesScanned: 0,
            potentialThreats: 0,
            totalMatches: 0
        });

        // Emit start simulation event
        socketRef.current.emit('start_simulation');

        // Set a timeout to check if simulation started
        setTimeout(() => {
            if (isSimulating && simulationProgress === 0) {
                console.error('[Socket] Simulation start timeout');
                setError('Simulation failed to start. Please try again.');
                setIsSimulating(false);
            }
        }, 10000); // 10 second timeout
    }, [roomJoined, isSimulating, simulationProgress]);

    const stopSimulation = useCallback(() => {
        if (!socketRef.current?.connected) {
            console.error('[Socket] Cannot stop simulation: not connected');
            setError('Not connected to server');
            return;
        }
        console.log('[Socket] Stopping simulation');
        socketRef.current.emit('stop_simulation');
        
        // Reset state
        setIsSimulating(false);
        setSimulationProgress(0);
        setCurrentSite(null);
        setCurrentStage(null);
        setStatusMessage('Scan stopped');
        setError(null);
    }, []);

    const emit = useCallback((event, data) => {
        if (!socketRef.current?.connected) {
            console.error('[Socket] Cannot emit event: not connected');
            return;
        }
        if (!roomJoined && event !== 'join') {
            console.error('[Socket] Cannot emit event: not joined to room');
            return;
        }
        socketRef.current.emit(event, data);
    }, [roomJoined]);

    useEffect(() => {
        if (userId) {
            connect();
        }
        return cleanup;
    }, [userId, connect, cleanup]);

    return {
        connected,
        roomJoined,
        error,
        isSimulating,
        simulationProgress,
        currentSite,
        currentStage,
        statusMessage,
        metrics,
        emit,
        startSimulation,
        stopSimulation
    };
};