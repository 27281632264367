import React, { memo } from 'react';
import { motion } from 'framer-motion';

const ChatInput = memo(({ value, onChange, onSend, disabled }) => {
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !disabled) {
            onSend();
        }
    };

    return (
        <div className="input-area">
            <div className="input-area-content">
                <input
                    type="text"
                    className="input-focus"
                    placeholder="Ask me about any security concern..."
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    onKeyPress={handleKeyPress}
                    disabled={disabled}
                />
                <motion.button 
                    className="send-button" 
                    onClick={onSend}
                    whileHover={{ y: -2 }}
                    whileTap={{ scale: 0.98 }}
                    disabled={disabled || !value.trim()}
                >
                    Send
                </motion.button>
            </div>
        </div>
    );
});

ChatInput.displayName = 'ChatInput';

export default ChatInput; 