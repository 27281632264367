import { useRef, useEffect } from 'react';

export const useScrollToBottom = (messages, showWelcome) => {
    const chatContainerRef = useRef(null);
    const welcomeMessageRef = useRef(null);

    // Scroll to bottom when new messages are added
    useEffect(() => {
        if (messages.length > 0) {
            const scrollToBottom = (behavior = 'smooth') => {
                if (chatContainerRef.current) {
                    const scrollHeight = chatContainerRef.current.scrollHeight;
                    chatContainerRef.current.scrollTo({
                        top: scrollHeight,
                        behavior
                    });
                }
            };
            scrollToBottom();
        }
    }, [messages]);

    // Scroll to welcome message when it's shown
    useEffect(() => {
        if (showWelcome && welcomeMessageRef.current) {
            welcomeMessageRef.current.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'start' 
            });
        }
    }, [showWelcome]);

    return {
        chatContainerRef,
        welcomeMessageRef
    };
};

export default useScrollToBottom; 