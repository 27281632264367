import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

// Create axios instance with base configuration
const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add request interceptor
api.interceptors.request.use(
    (config) => {
        // Ensure URL starts with /api/
        if (!config.url.startsWith('/api/')) {
            config.url = `/api/${config.url.replace(/^\/+/, '')}`;
        }
        
        console.log('[AuthService] Making request to:', config.url);
        return config;
    },
    (error) => Promise.reject(error)
);

// Add response interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401 && !error.config?.url.includes('/login')) {
            authService.logout();
        }
        return Promise.reject(error);
    }
);

const persistLog = (message, data = {}) => {
    const timestamp = new Date().toISOString();
    const logEntry = {
        timestamp,
        message,
        data
    };
    
    // Store in localStorage for persistence
    const logs = JSON.parse(localStorage.getItem('auth_debug_logs') || '[]');
    logs.push(logEntry);
    localStorage.setItem('auth_debug_logs', JSON.stringify(logs.slice(-50))); // Keep last 50 logs
    
    // Also log to console in development
    if (process.env.NODE_ENV === 'development') {
        console.log(`[AuthService] ${message}`, data);
    }
};

class AuthService {
    constructor() {
        this.token = null;
        this.user = null;
        this.checkInProgress = false;
        this.lastCheck = 0;
        this.authCheckDebounceTime = 2000; // 2 seconds
        
        persistLog('Initializing AuthService');
    }

    initializeFromStorage() {
        try {
            // Always try to get token from storage
            const token = localStorage.getItem('token') || sessionStorage.getItem('token');
            if (token) {
                persistLog('Found token in storage');
                this.setAuthToken(token);
                
                const userStr = localStorage.getItem('user') || sessionStorage.getItem('user');
                if (userStr) {
                    try {
                        const userData = JSON.parse(userStr);
                        if (userData && userData.id) {
                            this.user = userData;
                            persistLog('Loaded user data from storage', { userId: userData.id });
                            return true;
                        }
                    } catch (e) {
                        persistLog('Error parsing stored user data', { error: e.message });
                    }
                }
            }
            return false;
        } catch (error) {
            persistLog('Error initializing from storage', { error: error.message });
            return false;
        }
    }

    setAuthToken(token) {
        try {
            if (token) {
                // Clean token if it has Bearer prefix
                const cleanToken = token.startsWith('Bearer ') ? token.slice(7) : token;
                
                // Set axios default header
                api.defaults.headers.common['Authorization'] = `Bearer ${cleanToken}`;
                
                // Store in both storages for consistency
                localStorage.setItem('token', cleanToken);
                sessionStorage.setItem('token', cleanToken);
                
                this.token = cleanToken;
                console.log('[AuthService] Token set and stored successfully');
            } else {
                delete api.defaults.headers.common['Authorization'];
                localStorage.removeItem('token');
                sessionStorage.removeItem('token');
                localStorage.removeItem('user');
                sessionStorage.removeItem('user');
                this.token = null;
                this.user = null;
                console.log('[AuthService] Token and user data cleared');
            }
        } catch (error) {
            console.error('[AuthService] Error setting auth token:', error);
            this.token = null;
            this.user = null;
        }
    }

    async login(email, password, remember = false) {
        try {
            console.log('[AuthService] Attempting login...', { email, remember });
            
            const response = await api.post('/users/login', {
                email,
                password,
                remember
            });

            console.log('[AuthService] Login response:', {
                status: response.status,
                success: response.data.success,
                hasToken: !!response.data.token,
                hasUser: !!response.data.user
            });

            if (response.data.success) {
                const { token, user } = response.data;
                
                // Set token first
                this.setAuthToken(token);
                
                // Then store user data
                this.user = user;
                const storage = remember ? localStorage : sessionStorage;
                storage.setItem('user', JSON.stringify(user));

                return {
                    success: true,
                    user,
                    token
                };
            }

            return {
                success: false,
                error: response.data.error || 'Login failed'
            };

        } catch (error) {
            console.error('[AuthService] Login error:', {
                message: error.message,
                status: error.response?.status,
                data: error.response?.data
            });
            
            // Clear any existing tokens on login failure
            this.setAuthToken(null);
            
            return {
                success: false,
                error: error.response?.data?.error || 'Login failed. Please check your credentials and try again.'
            };
        }
    }

    logout() {
        console.log('[AuthService] Logging out...');
        
        // Clear all storage
        this.setAuthToken(null);
        
        // Dispatch logout event for navigation handling
        window.dispatchEvent(new Event('auth-logout'));
    }

    async checkAuth() {
        try {
            const token = this.getToken();
            if (!token) {
                console.log('[AuthService] No token found during auth check');
                return false;
            }

            // Ensure the token is set in axios defaults
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            
            const response = await api.get('/users/me');
            
            if (response.data?.success && response.data?.user) {
                // Update user data in storage
                const storage = localStorage.getItem('token') ? localStorage : sessionStorage;
                storage.setItem('user', JSON.stringify(response.data.user));
                this.user = response.data.user;
                return true;
            }

            console.log('[AuthService] Invalid response from /users/me:', response.data);
            return false;
        } catch (error) {
            console.error('[AuthService] Auth check error:', {
                status: error.response?.status,
                message: error.message,
                data: error.response?.data
            });

            // Only logout on specific error conditions
            if (error.response?.status === 401) {
                this.logout();
                return false;
            }

            // For 500 errors, don't immediately logout - might be temporary
            if (error.response?.status === 500) {
                console.log('[AuthService] Server error during auth check - will retry');
                return true; // Return true to prevent immediate logout
            }

            return false;
        }
    }

    async getUserDetails() {
        try {
            const token = this.getToken();
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await api.get('/users/details', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.success) {
                return response.data.user;
            }

            throw new Error('Failed to fetch user details');
        } catch (error) {
            console.error('[AuthService] Get user details failed:', error);
            throw error;
        }
    }

    async updateProfile(profileData) {
        try {
            const token = this.getToken();
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await api.put('/users/profile', profileData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data.success) {
                // Update local user data
                this.user = { ...this.user, ...response.data.user };
                const storage = localStorage.getItem('token') ? localStorage : sessionStorage;
                storage.setItem('user', JSON.stringify(this.user));
                return response.data.user;
            }

            throw new Error('Failed to update profile');
        } catch (error) {
            console.error('[AuthService] Update profile failed:', error);
            throw error;
        }
    }

    getUser() {
        if (this.user) {
            return this.user;
        }

        const userStr = localStorage.getItem('user') || sessionStorage.getItem('user');
        if (userStr) {
            try {
                this.user = JSON.parse(userStr);
                return this.user;
            } catch (e) {
                console.error('[AuthService] Error parsing user data:', e);
            }
        }

        return null;
    }

    isAuthenticated() {
        const token = this.getToken();
        return !!token;
    }

    getAuthHeaders() {
        const token = this.getToken();
        return token ? { Authorization: `Bearer ${token}` } : {};
    }

    getToken() {
        if (!this.token) {
            const token = localStorage.getItem('token') || sessionStorage.getItem('token');
            if (token) {
                this.setAuthToken(token);
            }
        }
        return this.token;
    }
}

// Create a single instance
const authService = new AuthService();

// Export the getToken function that uses the authService instance
export const getToken = () => authService.getToken();

// Export the auth service and the configured axios instance
export { api };
export default authService;
