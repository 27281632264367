import React from 'react';
import { FaShieldAlt, FaPlus } from 'react-icons/fa';
import { motion } from 'framer-motion';

const ChatHeader = ({ onNewChat }) => {
    return (
        <div className="top-section">
            <motion.div 
                className="raven-header"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
            >
                <div className="raven-logo-section">
                    <FaShieldAlt className="raven-icon" />
                    <div className="raven-text">
                        <h1 className="raven-title">RavenAI Security</h1>
                        <p className="raven-subtitle">World Experts in Preventing Scams &amp; Identity Fraud</p>
                    </div>
                </div>
                <motion.button 
                    className="new-chat-button" 
                    onClick={onNewChat}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <FaPlus /> New Chat
                </motion.button>
            </motion.div>
        </div>
    );
};

export default ChatHeader; 