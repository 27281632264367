import React, { memo } from 'react';

const ThinkingIndicator = memo(({ stage }) => {
    return (
        <div className="thinking-indicator">
            <div className="thinking-content">
                <div className="thinking-dots">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <p>{stage || 'Analyzing your security concern...'}</p>
            </div>
        </div>
    );
});

ThinkingIndicator.displayName = 'ThinkingIndicator';

export default ThinkingIndicator; 