import React, { useState, useEffect } from 'react';
import { api } from '../services/authService';
import { useAuth } from '../context/AuthContext';
import './FeatureToggles.css';

const FeatureToggle = ({ label, isEnabled, onChange, isLoading }) => (
    <div className="feature-toggle-item">
        <label className="toggle-label">{label}</label>
        <div className={`toggle-switch ${isEnabled ? 'enabled' : ''} ${isLoading ? 'loading' : ''}`}
             onClick={() => !isLoading && onChange(!isEnabled)}>
            <div className="toggle-slider">
                {isLoading && <div className="toggle-loading"></div>}
            </div>
        </div>
    </div>
);

const FeatureToggles = ({ toggles: initialToggles = {} }) => {
    const [loading, setLoading] = useState({});
    const [localToggles, setLocalToggles] = useState(initialToggles);
    const [error, setError] = useState(null);
    const { isAuthenticated } = useAuth();

    const features = [
        { key: 'multi_factor_auth', label: 'Multi Factor Auth' },
        { key: 'role_based_access', label: 'Role Based Access' },
        { key: 'monitoring_verification', label: 'Monitoring Verification' },
        { key: 'data_leak_notification', label: 'Data Leak Notification' }
    ];

    // Fetch initial toggles
    useEffect(() => {
        const fetchToggles = async () => {
            if (!isAuthenticated) return;

            try {
                const response = await api.get('/feature-toggles');
                
                if (response.data.success && response.data.featureToggles) {
                    setLocalToggles(response.data.featureToggles);
                    setError(null);
                } else {
                    throw new Error('Failed to fetch features');
                }
            } catch (error) {
                console.error('Error fetching feature toggles:', error);
                setError('Failed to load features. Please refresh the page.');
                setLocalToggles(initialToggles);
            }
        };

        fetchToggles();
    }, [isAuthenticated, initialToggles]);

    const handleToggle = async (toggleName) => {
        if (!isAuthenticated) {
            setError('Please log in to modify features');
            return;
        }

        setLoading(prev => ({ ...prev, [toggleName]: true }));
        setError(null);
        
        try {
            const response = await api.post('/feature-toggles/update', {
                toggleName,
                value: !localToggles[toggleName]
            });

            if (response.data.success) {
                // Update local state with the response from the server
                if (response.data.featureToggles) {
                    setLocalToggles(response.data.featureToggles);
                } else {
                    // Fallback to optimistic update if server doesn't return new state
                    setLocalToggles(prev => ({
                        ...prev,
                        [toggleName]: !prev[toggleName]
                    }));
                }
            } else {
                throw new Error(response.data.message || 'Failed to update feature');
            }
        } catch (error) {
            console.error('Error updating feature toggle:', error);
            
            if (error.response?.status === 403) {
                setError('Subscription required to modify features');
            } else {
                setError('Failed to update feature. Please try again.');
            }
            
            // Revert the optimistic update
            setLocalToggles(prev => ({
                ...prev,
                [toggleName]: localToggles[toggleName]
            }));
        } finally {
            setLoading(prev => ({ ...prev, [toggleName]: false }));
        }
    };

    return (
        <div className="feature-toggles-container">
            <h3 className="feature-toggles-title">Features</h3>
            {error && (
                <div className="feature-toggles-error">
                    <i className="fas fa-exclamation-circle"></i>
                    {error}
                </div>
            )}
            <div className="feature-toggles-list">
                {features.map(({ key, label }) => (
                    <FeatureToggle
                        key={key}
                        label={label}
                        isEnabled={localToggles[key]}
                        onChange={() => handleToggle(key)}
                        isLoading={loading[key]}
                    />
                ))}
            </div>
        </div>
    );
};

export default FeatureToggles;