import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import MobileNavbar from '../components/MobileNavbar';
import ChatHeader from '../components/chat/ChatHeader';
import WelcomeMessage from '../components/chat/WelcomeMessage';
import ChatMessages from '../components/chat/ChatMessages';
import ChatInput from '../components/chat/ChatInput';
import ThinkingIndicator from '../components/chat/ThinkingIndicator';
import { useChat } from '../hooks/useChat';
import { useScrollToBottom } from '../hooks/useScrollToBottom';
import { scamKeywords, preMadeQuestions } from '../config/chatConstants';
import { AnimatePresence } from 'framer-motion';
import './ChatPage.css';

const ChatPage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const {
        messages,
        userInput,
        showWelcome,
        thinkingStage,
        isAskingName,
        isTyping,
        setUserInput,
        handleSendMessage,
        handlePreMadeQuestionClick,
        handleNewChat,
        handleOptionClick
    } = useChat();

    const { chatContainerRef, welcomeMessageRef } = useScrollToBottom(messages, showWelcome);

    return (
        <div className="chat-page">
            {/* Navbar */}
            {isMobile ? <MobileNavbar /> : <Navbar />}

            {/* Header section */}
            <ChatHeader onNewChat={handleNewChat} />

            {/* Main chat container */}
            <div className="chat-container" ref={chatContainerRef}>
                <AnimatePresence mode="popLayout">
                    {showWelcome && messages.length === 0 && (
                        <WelcomeMessage 
                            key="welcome"
                            ref={welcomeMessageRef}
                            preMadeQuestions={preMadeQuestions}
                            onQuestionClick={handlePreMadeQuestionClick}
                        />
                    )}
                    
                    <ChatMessages 
                        key="messages"
                        messages={messages}
                        scamKeywords={scamKeywords}
                        isTyping={isTyping}
                        onOptionClick={handleOptionClick}
                    />
                    
                    {thinkingStage && <ThinkingIndicator key="thinking" stage={thinkingStage} />}
                </AnimatePresence>
            </div>

            {/* Input Area */}
            <ChatInput
                value={userInput}
                onChange={setUserInput}
                onSend={handleSendMessage}
                disabled={isAskingName || isTyping}
            />
        </div>
    );
};

export default ChatPage;
