// javascript:src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { io } from 'socket.io-client';
import authService from '../services/authService';
import { SOCKET_URL } from '../config/api';

const AuthContext = createContext(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

const persistLog = (message, data = {}) => {
    const timestamp = new Date().toISOString();
    const logEntry = {
        timestamp,
        message,
        data
    };
    
    // Store in localStorage for persistence
    const logs = JSON.parse(localStorage.getItem('auth_debug_logs') || '[]');
    logs.push(logEntry);
    localStorage.setItem('auth_debug_logs', JSON.stringify(logs.slice(-50))); // Keep last 50 logs
    
    // Also log to console
    console.log(`[AuthContext] ${message}`, data);
};

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [socket, setSocket] = useState(null);
    const [error, setError] = useState(null);
    const authCheckTimeoutRef = useRef(null);
    const socketReconnectTimeoutRef = useRef(null);
    const initializingRef = useRef(false);
    const loginInProgressRef = useRef(false);

    // Initialize auth state
    useEffect(() => {
        const initialize = async () => {
            if (initializingRef.current) {
                persistLog('Initialization already in progress, skipping');
                return;
            }
            
            initializingRef.current = true;
            persistLog('Starting auth initialization');
            
            try {
                // Check if we have a token and it's valid
                const hasToken = authService.initializeFromStorage();
                
                if (hasToken) {
                    persistLog('Found token, verifying');
                    try {
                        const isValid = await authService.checkAuth();
                        if (isValid) {
                            const userData = authService.getUser();
                            persistLog('Token verified successfully', { 
                                hasUser: !!userData,
                                userId: userData?.id
                            });
                            setIsAuthenticated(true);
                            setUser(userData);
                            setError(null);
                        } else {
                            persistLog('Token verification failed');
                            handleLogout();
                        }
                    } catch (err) {
                        persistLog('Token verification error', { error: err.message });
                        handleLogout();
                    }
                } else {
                    persistLog('No token found');
                    handleLogout();
                }
            } catch (err) {
                persistLog('Initialization error', { error: err.message });
                handleLogout();
            } finally {
                setLoading(false);
                initializingRef.current = false;
            }
        };

        initialize();

        return () => {
            if (authCheckTimeoutRef.current) {
                clearTimeout(authCheckTimeoutRef.current);
            }
            if (socketReconnectTimeoutRef.current) {
                clearTimeout(socketReconnectTimeoutRef.current);
            }
            if (socket) {
                socket.disconnect();
            }
        };
    }, []);

    const handleLogin = useCallback(async (email, password, remember = false) => {
        if (loginInProgressRef.current) {
            persistLog('Login already in progress');
            return { success: false, error: 'Login in progress' };
        }

        loginInProgressRef.current = true;
        persistLog('Starting login process', { email });

        try {
            const result = await authService.login(email, password, remember);
            
            if (result.success) {
                persistLog('Login successful', { 
                    userId: result.user.id
                });
                
                setIsAuthenticated(true);
                setUser(result.user);
                setError(null);
                
                return { success: true };
            } else {
                persistLog('Login failed', { error: result.error });
                setError(result.error);
                return { success: false, error: result.error };
            }
        } catch (err) {
            persistLog('Login error', { error: err.message });
            setError(err.message);
            return { success: false, error: err.message };
        } finally {
            loginInProgressRef.current = false;
        }
    }, []);

    const handleLogout = useCallback(() => {
        persistLog('Starting logout process');
        
        // Clean up socket
        if (socket) {
            socket.disconnect();
            setSocket(null);
        }
        
        // Clear timeouts
        if (authCheckTimeoutRef.current) {
            clearTimeout(authCheckTimeoutRef.current);
        }
        if (socketReconnectTimeoutRef.current) {
            clearTimeout(socketReconnectTimeoutRef.current);
        }
        
        // Clear auth state
        authService.logout();
        setIsAuthenticated(false);
        setUser(null);
        setError(null);
        
        persistLog('Logout complete');
    }, [socket]);

    const value = {
        isAuthenticated,
        user,
        loading,
        error,
        login: handleLogin,
        logout: handleLogout
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;