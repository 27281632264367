import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
  Stack,
  Checkbox,
  TableSortLabel,
  Tooltip,
  CircularProgress,
  InputAdornment,
  Alert,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Security as SecurityIcon,
  Search as SearchIcon,
  PlayArrow as RunIcon,
} from '@mui/icons-material';
import { format } from 'date-fns';
import socketService from '../../services/socketService';

const ADMIN_API = 'https://cleandata-api-25456108afa7.herokuapp.com/api/admin';
const DASHBOARD_API = 'https://cleandata-api-25456108afa7.herokuapp.com/api';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortField, setSortField] = useState('joined_at');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage] = useState(10);
  const [success, setSuccess] = useState(null);

  const fetchUsers = async (page = 1, searchQuery = '', sortBy = sortField, order = sortOrder) => {
    setLoading(true);
    try {
        const controller = new AbortController();
        const response = await fetch(
            `${ADMIN_API}/users?page=${page}&limit=${rowsPerPage}&search=${searchQuery}`,
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                signal: controller.signal
            }
        );

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to fetch users');
        }

        const data = await response.json();
        setUsers(data.users);
        setTotalPages(Math.ceil(data.total / rowsPerPage));
        setCurrentPage(data.page);
        return controller;
    } catch (err) {
        if (err.name === 'AbortError') {
            return;
        }
        setError(err.message);
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    let controller;

    const loadUsers = async () => {
        if (isMounted) {
            controller = await fetchUsers(currentPage, search, sortField, sortOrder);
        }
    };

    const initializeSocket = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token available');
            }

            // Initialize socket connection
            await socketService.connect(token);

            // Set up WebSocket listeners
            socketService.on('user_updated', (updatedUser) => {
                if (isMounted) {
                    setUsers(prevUsers => prevUsers.map(user => 
                        user.id === updatedUser.id ? updatedUser : user
                    ));
                }
            });

            socketService.on('simulation_progress', (data) => {
                if (isMounted) {
                    console.log('Simulation progress:', data);
                }
            });

            socketService.on('simulation_error', (error) => {
                if (isMounted) {
                    setError(error.message || 'Simulation error occurred');
                }
            });

        } catch (error) {
            console.error('Failed to initialize socket:', error);
            setError('Failed to connect to real-time updates');
        }
    };

    loadUsers();
    initializeSocket();

    return () => {
        isMounted = false;
        if (controller) {
            controller.abort();
        }
        // Clean up socket listeners
        socketService.off('user_updated');
        socketService.off('simulation_progress');
        socketService.off('simulation_error');
    };
  }, [currentPage, search, sortField, sortOrder]);

  const handleSort = (field) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortField(field);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedUsers(users.map(user => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (userId) => {
    setSelectedUsers(prev => {
      if (prev.includes(userId)) {
        return prev.filter(id => id !== userId);
      }
      return [...prev, userId];
    });
  };

  const handleDeleteSelected = async () => {
    if (!window.confirm(`Are you sure you want to delete ${selectedUsers.length} users?`)) {
      return;
    }

    try {
      await Promise.all(selectedUsers.map(userId =>
        fetch(`${ADMIN_API}/users/${userId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        })
      ));

      fetchUsers(currentPage, search);
      setSelectedUsers([]);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEditUser = async (userId, updates) => {
    try {
        setLoading(true);
        const response = await fetch(`${ADMIN_API}/users/${userId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: updates.email,
                firstName: updates.first_name,
                lastName: updates.last_name,
                role: updates.role,
                subscription_status: updates.subscription_status
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to update user');
        }

        const data = await response.json();
        setSuccess('User updated successfully');
        fetchUsers(currentPage, search);
        setOpenDialog(false);
    } catch (err) {
        setError(err.message);
    } finally {
        setLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user?')) {
      return;
    }

    try {
      const response = await fetch(`${ADMIN_API}/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete user');
      }

      fetchUsers(currentPage, search);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSimulateScan = async (userId) => {
    try {
        setLoading(true);
        const response = await fetch(`${ADMIN_API}/scan/${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                scanType: 'full'
            })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setSuccess('Scan started successfully');

        // Listen for scan events
        socketService.on('simulation_progress', (progressData) => {
            console.log('Scan progress:', progressData);
            // You can update UI with progress if needed
        });

        socketService.on('simulation_complete', (completionData) => {
            console.log('Scan completed:', completionData);
            setSuccess('Scan completed successfully');
            // Clean up listeners
            socketService.off('simulation_progress');
            socketService.off('simulation_complete');
        });

        socketService.on('simulation_error', (errorData) => {
            console.error('Scan error:', errorData);
            setError(errorData.message || 'Error during scan');
            // Clean up listeners
            socketService.off('simulation_progress');
            socketService.off('simulation_complete');
            socketService.off('simulation_error');
        });

    } catch (error) {
        console.error('Error starting scan:', error);
        setError(error.message || 'Failed to start scan');
    } finally {
        setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      return format(new Date(dateString), 'MMM d, yyyy');
    } catch (error) {
      return 'Invalid Date';
    }
  };

  const getSubscriptionStatus = (user) => {
    // Default to 'expired' if no subscription status is set
    return user.subscription_status || 'expired';
  };

  const getSubscriptionChipProps = (status) => {
    const baseStyles = {
      size: "small",
      sx: {
        backgroundColor: 'rgba(255, 67, 67, 0.2)',
        color: '#ff4343',
      }
    };

    switch (status) {
      case 'premium':
        return {
          ...baseStyles,
          sx: {
            backgroundColor: 'rgba(216, 255, 96, 0.2)',
            color: '#D8FF60',
          }
        };
      case 'trial':
        return {
          ...baseStyles,
          sx: {
            backgroundColor: 'rgba(255, 152, 0, 0.2)',
            color: '#ff9800',
          }
        };
      default: // expired or any other status
        return baseStyles;
    }
  };

  const shortenUUID = (uuid) => {
    return uuid.split('-')[0];
  };

  return (
    <Box sx={{ color: '#fff' }}>
      <Box mb={3} display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
        <Typography variant="h5" sx={{ color: '#fff' }}>User Management</Typography>
        <Box display="flex" gap={2}>
          {selectedUsers.length > 0 && (
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteSelected}
              sx={{
                backgroundColor: 'rgba(255, 67, 67, 0.8)',
                '&:hover': {
                  backgroundColor: '#ff4343',
                },
              }}
            >
              Delete Selected ({selectedUsers.length})
            </Button>
          )}
          <TextField
            size="small"
            placeholder="Search users..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              minWidth: 250,
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.1)',
                },
                '&:hover fieldset': {
                  borderColor: '#D8FF60',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D8FF60',
                },
              },
            }}
          />
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ 
        backgroundColor: '#1E1E1E',
        boxShadow: 'none',
        border: '1px solid rgba(216, 255, 96, 0.05)',
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                <Checkbox
                  indeterminate={selectedUsers.length > 0 && selectedUsers.length < users.length}
                  checked={selectedUsers.length === users.length && users.length > 0}
                  onChange={handleSelectAll}
                  sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&.Mui-checked': {
                      color: '#D8FF60',
                    },
                  }}
                />
              </TableCell>
              <TableCell sx={{ color: '#fff', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                <TableSortLabel
                  active={sortField === 'id'}
                  direction={sortField === 'id' ? sortOrder : 'asc'}
                  onClick={() => handleSort('id')}
                  sx={{
                    color: '#fff !important',
                    '& .MuiTableSortLabel-icon': {
                      color: '#D8FF60 !important',
                    },
                  }}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: '#fff', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                <TableSortLabel
                  active={sortField === 'email'}
                  direction={sortField === 'email' ? sortOrder : 'asc'}
                  onClick={() => handleSort('email')}
                  sx={{
                    color: '#fff !important',
                    '& .MuiTableSortLabel-icon': {
                      color: '#D8FF60 !important',
                    },
                  }}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: '#fff', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                <TableSortLabel
                  active={sortField === 'role'}
                  direction={sortField === 'role' ? sortOrder : 'asc'}
                  onClick={() => handleSort('role')}
                  sx={{
                    color: '#fff !important',
                    '& .MuiTableSortLabel-icon': {
                      color: '#D8FF60 !important',
                    },
                  }}
                >
                  Role
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: '#fff', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                <TableSortLabel
                  active={sortField === 'subscription_status'}
                  direction={sortField === 'subscription_status' ? sortOrder : 'asc'}
                  onClick={() => handleSort('subscription_status')}
                  sx={{
                    color: '#fff !important',
                    '& .MuiTableSortLabel-icon': {
                      color: '#D8FF60 !important',
                    },
                  }}
                >
                  Subscription
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: '#fff', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                <TableSortLabel
                  active={sortField === 'joined_at'}
                  direction={sortField === 'joined_at' ? sortOrder : 'asc'}
                  onClick={() => handleSort('joined_at')}
                  sx={{
                    color: '#fff !important',
                    '& .MuiTableSortLabel-icon': {
                      color: '#D8FF60 !important',
                    },
                  }}
                >
                  Joined
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ color: '#fff', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                  <CircularProgress sx={{ color: '#D8FF60' }} />
                </TableCell>
              </TableRow>
            ) : users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center" sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                  <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                    No users found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              users.map((user) => (
                <TableRow 
                  key={user.id} 
                  sx={{ 
                    '&:hover': { 
                      backgroundColor: 'rgba(216, 255, 96, 0.1)',
                    },
                    backgroundColor: selectedUsers.includes(user.id) ? 'rgba(216, 255, 96, 0.05)' : 'transparent',
                  }}
                >
                  <TableCell padding="checkbox" sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    <Checkbox
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleSelectUser(user.id)}
                      sx={{
                        color: 'rgba(255, 255, 255, 0.7)',
                        '&.Mui-checked': {
                          color: '#D8FF60',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ color: '#fff', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    {shortenUUID(user.id)}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    {user.email}
                  </TableCell>
                  <TableCell sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    <Chip
                      label={user.role}
                      size="small"
                      sx={{
                        backgroundColor: user.role === 'admin' ? 'rgba(216, 255, 96, 0.2)' : 'rgba(99, 179, 237, 0.2)',
                        color: user.role === 'admin' ? '#D8FF60' : '#63b3ed',
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    <Chip
                      label={getSubscriptionStatus(user)}
                      {...getSubscriptionChipProps(getSubscriptionStatus(user))}
                    />
                  </TableCell>
                  <TableCell sx={{ color: '#fff', borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    {formatDate(user.joined_at)}
                  </TableCell>
                  <TableCell sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>
                    <Box display="flex" gap={1}>
                      <Tooltip title="Edit User">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSelectedUser(user);
                            setOpenDialog(true);
                          }}
                          sx={{ color: '#D8FF60' }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete User">
                        <IconButton
                          size="small"
                          onClick={() => handleDeleteUser(user.id)}
                          sx={{ color: '#ff4343' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Run Simulated Scan">
                        <IconButton
                          size="small"
                          onClick={() => handleSimulateScan(user.id)}
                          sx={{ color: '#D8FF60' }}
                        >
                          <RunIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
          sx={{
            '& .MuiPaginationItem-root': {
              color: '#fff',
              '&.Mui-selected': {
                backgroundColor: 'rgba(216, 255, 96, 0.2)',
                color: '#D8FF60',
              },
              '&:hover': {
                backgroundColor: 'rgba(216, 255, 96, 0.1)',
              },
            },
          }}
        />
      </Box>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            backgroundColor: '#1E1E1E',
            color: '#fff',
          },
        }}
      >
        <DialogTitle sx={{ borderBottom: '1px solid rgba(255, 255, 255, 0.1)' }}>Edit User</DialogTitle>
        <DialogContent>
          {selectedUser && (
            <Stack spacing={2} sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel sx={{ color: '#fff' }}>Role</InputLabel>
                <Select
                  value={selectedUser.role}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, role: e.target.value })
                  }
                  sx={{
                    color: '#fff',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D8FF60',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D8FF60',
                    },
                  }}
                >
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel sx={{ color: '#fff' }}>Subscription Status</InputLabel>
                <Select
                  value={selectedUser.subscription_status}
                  onChange={(e) =>
                    setSelectedUser({
                      ...selectedUser,
                      subscription_status: e.target.value,
                    })
                  }
                  sx={{
                    color: '#fff',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D8FF60',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#D8FF60',
                    },
                  }}
                >
                  <MenuItem value="trial">Trial</MenuItem>
                  <MenuItem value="premium">Premium</MenuItem>
                  <MenuItem value="expired">Expired</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          )}
        </DialogContent>
        <DialogActions sx={{ borderTop: '1px solid rgba(255, 255, 255, 0.1)', padding: 2 }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            sx={{ 
              color: '#fff',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleEditUser(selectedUser.id, selectedUser)}
            variant="contained"
            sx={{
              backgroundColor: '#D8FF60',
              color: '#000',
              '&:hover': {
                backgroundColor: '#b3ff00',
              },
            }}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mt: 2,
            backgroundColor: 'rgba(255, 67, 67, 0.1)',
            color: '#ff4343',
            border: '1px solid rgba(255, 67, 67, 0.2)',
            '& .MuiAlert-icon': {
              color: '#ff4343',
            },
          }}
        >
          {error}
        </Alert>
      )}

      {success && (
        <Alert 
          severity="success" 
          sx={{ 
            mt: 2,
            backgroundColor: 'rgba(216, 255, 96, 0.1)',
            color: '#D8FF60',
            border: '1px solid rgba(216, 255, 96, 0.2)',
            '& .MuiAlert-icon': {
              color: '#D8FF60',
            },
          }}
        >
          {success}
        </Alert>
      )}
    </Box>
  );
};

export default UserManagement; 