import React, { useState, useEffect } from 'react';
import './DashboardStats.css';
import { ScannerOutlined } from '@mui/icons-material';

const StatCard = ({ title, value, loading }) => (
    <div className="stat-card">
        <h3 className="stat-title">{title}</h3>
        <div className="stat-value">
            {value}
        </div>
        {loading && <div className="loading-indicator" />}
    </div>
);

const DashboardStats = ({ stats }) => {
    const TOTAL_SITES = 10;
    const [countdown, setCountdown] = useState('');

    useEffect(() => {
        const updateCountdown = () => {
            const now = new Date();
            const nextHour = new Date(now);
            nextHour.setHours(now.getHours() + 1);
            nextHour.setMinutes(0);
            nextHour.setSeconds(0);
            nextHour.setMilliseconds(0);

            const diff = nextHour - now;
            const minutes = Math.floor(diff / 60000);
            const seconds = Math.floor((diff % 60000) / 1000);
            setCountdown(`${minutes}m ${seconds}s`);
        };

        updateCountdown();
        const interval = setInterval(updateCountdown, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatSitesScanned = () => {
        if (stats?.isScanning) {
            const progress = Math.round((stats?.progress || 0) * 100) / 100;
            return `${progress}%`;
        }
        if (stats?.lastScanTime) {
            return '100%';
        }
        return 'Waiting...';
    };

    return (
        <div className="dashboard-stats">
            <div className="stat-card">
                <h3 className="stat-title">SITES SCANNED</h3>
                <div className="stat-value">
                    {formatSitesScanned()}
                </div>
                <div className="progress-bar">
                    <div 
                        className="progress-fill" 
                        style={{ 
                            width: stats?.isScanning ? `${stats?.progress || 0}%` : 
                                  stats?.lastScanTime ? '100%' : '0%'
                        }}
                    />
                </div>
                <p className="stat-description">Data broker sites analyzed</p>
                <div className="automated-scan-info">
                    <i className="fas fa-clock"></i>
                    <div className="scan-info-content">
                        <span>
                            {stats?.isScanning ? 
                                'Scan in progress...' : 
                                'Automated scans run at the beginning of every hour'
                            }
                        </span>
                        <div className="next-scan-countdown">
                            Next scan in: {countdown}
                        </div>
                    </div>
                </div>
            </div>

            <div className="stat-card">
                <h3 className="stat-title">POTENTIAL THREATS</h3>
                <div className="stat-value">
                    {stats?.potentialThreats || 0}
                </div>
                <p className="stat-description">Possible exposures identified</p>
            </div>

            <div className="stat-card">
                <h3 className="stat-title">TOTAL MATCHES</h3>
                <div className="stat-value">
                    {stats?.totalMatches || 0}
                </div>
                <p className="stat-description">Cumulative matches across platforms</p>
            </div>
        </div>
    );
};

export default DashboardStats;
