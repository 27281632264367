import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import laptopImage from '../assets/laptop-image.png';
import './LoginPage.css';
import Navbar from '../components/Navbar';
import MobileNavBar from '../components/MobileNavbar';

// IMPORTANT: DO NOT MODIFY THE BASE_URL - it must always end with /api
// This is required for all API endpoints to work correctly
const API_BASE_URL = 'https://cleandata-api-25456108afa7.herokuapp.com/api';

const PASSWORD_REQUIREMENTS = {
    minLength: 8,
    hasUpperCase: /[A-Z]/,
    hasLowerCase: /[a-z]/,
    hasNumber: /[0-9]/,
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/
};

const validatePassword = (password) => {
    const errors = [];
    const requirements = [];
    
    if (password.length < PASSWORD_REQUIREMENTS.minLength) {
        requirements.push('At least 8 characters');
    }
    if (!PASSWORD_REQUIREMENTS.hasUpperCase.test(password)) {
        requirements.push('One uppercase letter');
    }
    if (!PASSWORD_REQUIREMENTS.hasLowerCase.test(password)) {
        requirements.push('One lowercase letter');
    }
    if (!PASSWORD_REQUIREMENTS.hasNumber.test(password)) {
        requirements.push('One number');
    }
    if (!PASSWORD_REQUIREMENTS.hasSpecialChar.test(password)) {
        requirements.push('One special character (!@#$%^&*(),.?":{}|<>)');
    }
    
    if (requirements.length > 0) {
        errors.push('Your password needs:');
        errors.push(...requirements);
    }
    
    return errors;
};

const LoginPage = () => {
    const { login, isAuthenticated } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [isResetFlow, setIsResetFlow] = useState(false);
    const [isSetupFlow, setIsSetupFlow] = useState(false);
    const [setupToken, setSetupToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleRedirect = () => {
            // If already authenticated, redirect to appropriate page
            if (isAuthenticated) {
                const state = location.state || {};
                const searchParams = new URLSearchParams(location.search);
                const queryReturnUrl = searchParams.get('returnUrl');
                const returnUrl = state.returnUrl || queryReturnUrl || '/dashboard';

                // Ensure the return URL is safe
                const isSafeUrl = returnUrl.startsWith('/') && !returnUrl.startsWith('//');
                navigate(isSafeUrl ? returnUrl : '/dashboard', { replace: true });
                return;
            }
        };

        handleRedirect();

        // Extract setup token from URL if present
        const params = new URLSearchParams(location.search);
        const setupTokenParam = params.get('setup');
        if (setupTokenParam) {
            setIsSetupFlow(true);
            setSetupToken(setupTokenParam);
        }

        // Check for success message in location state
        if (location.state?.message) {
            setSuccessMessage(location.state.message);
        }

        // Only scroll on desktop
        if (window.innerWidth > 1024) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
        }

        // Cleanup function
        return () => {
            setSuccessMessage('');
            setError('');
        };
    }, [location, navigate, isAuthenticated]);

    const validateAndSetPassword = (newPassword) => {
        setPassword(newPassword);
        const errors = validatePassword(newPassword);
        setValidationErrors(errors);
        return errors.length === 0;
    };

    const handleSetupPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        // Validate passwords match
        if (password !== confirmPassword) {
            setError("The passwords you entered don't match. Please try again.");
            setLoading(false);
            return;
        }

        // Validate password requirements
        if (!validateAndSetPassword(password)) {
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/payment/setup-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ setupToken, password }),
                credentials: 'include'
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('Your password has been set up successfully! You can now log in to your account.');
                setIsSetupFlow(false);
                // Clear form
                setPassword('');
                setConfirmPassword('');
                setSetupToken('');
            } else {
                setError(data.message || "We couldn't set up your password. The setup link may have expired. Please request a new one.");
            }
        } catch (error) {
            setError("We're having trouble connecting to our servers. Please check your internet connection and try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleSendOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        try {
            const response = await fetch(`${API_BASE_URL}/password/forgot`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
                credentials: 'include'
            });

            const data = await response.json();

            if (response.ok) {
                setOtpSent(true);
                setSuccessMessage("We've sent a verification code to your email. Please check your inbox and spam folder.");
            } else {
                setError(data.message || "We couldn't send the verification code. Please make sure you entered the correct email address.");
            }
        } catch (error) {
            setError("We're having trouble connecting to our servers. Please check your internet connection and try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        if (!validateAndSetPassword(password)) {
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/password/reset`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, otp, newPassword: password }),
                credentials: 'include'
            });

            const data = await response.json();

            if (response.ok) {
                setSuccessMessage('Your password has been reset successfully! Please log in with your new password.');
                setTimeout(() => {
                    setIsResetFlow(false);
                    setOtpSent(false);
                }, 2000);
            } else {
                setError(data.message || "The verification code is incorrect or has expired. Please try again or request a new code.");
            }
        } catch (error) {
            setError("We're having trouble connecting to our servers. Please check your internet connection and try again.");
        } finally {
            setLoading(false);
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        try {
            const result = await login(email, password);
            
            if (result.success) {
                const params = new URLSearchParams(location.search);
                const returnUrl = params.get('returnUrl') || '/dashboard';
                navigate(returnUrl);
            } else {
                setError(result.error || "The email or password you entered is incorrect. Please try again.");
            }
        } catch (error) {
            setError("The email or password you entered is incorrect. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const getFormTitle = () => {
        if (isSetupFlow) return 'Welcome to Cleandata.me Pro';
        if (isResetFlow) return 'Reset Password';
        return 'Welcome Back';
    };

    const getFormSubtitle = () => {
        if (isSetupFlow) return 'Please set up your password to continue';
        if (isResetFlow) return 'Enter your email to receive a reset code';
        return 'Sign in to manage your data protection';
    };

    // Add a new component for password requirements
    const PasswordRequirements = ({ password }) => {
        const requirements = [
            { label: 'At least 8 characters', test: pwd => pwd.length >= 8 },
            { label: 'One uppercase letter', test: pwd => /[A-Z]/.test(pwd) },
            { label: 'One number', test: pwd => /[0-9]/.test(pwd) },
            { label: 'One special character', test: pwd => /[!@#$%^&*(),.?":{}|<>]/.test(pwd) }
        ];

        return (
            <div className="password-requirements">
                <div className="requirements-title">Password requirements:</div>
                <div className="requirements-list">
                    {requirements.map((req, index) => (
                        <div 
                            key={index} 
                            className={`requirement-item ${password && req.test(password) ? 'met' : ''}`}
                        >
                            <span className="requirement-icon">
                                {password && req.test(password) ? '✓' : '○'}
                            </span>
                            <span className="requirement-label">{req.label}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="login-page-wrapper">
            {window.innerWidth <= 768 ? <MobileNavBar /> : <Navbar />}
            
            <div className="auth-container">
                <div className="auth-wrapper">
                    <div className="auth-content">
                        <div className="auth-header">
                            <h1 className="brand">Cleandata.me Pro</h1>
                            <h2 className="auth-title">{getFormTitle()}</h2>
                            <p className="auth-subtitle">{getFormSubtitle()}</p>
                        </div>

                        {(isSetupFlow || (isResetFlow && otpSent)) && (
                            <PasswordRequirements password={password} />
                        )}

                        {successMessage && (
                            <div className="alert success" role="alert">
                                {successMessage}
                            </div>
                        )}

                        {error && (
                            <div className="alert error" role="alert">
                                {error}
                            </div>
                        )}

                        {validationErrors.length > 0 && (
                            <div className="validation-errors">
                                {validationErrors.map((error, index) => (
                                    <div key={index} className="validation-error">
                                        {error}
                                    </div>
                                ))}
                            </div>
                        )}

                        <form 
                            onSubmit={
                                isSetupFlow 
                                    ? handleSetupPassword 
                                    : isResetFlow 
                                        ? (otpSent ? handleVerifyOtp : handleSendOtp) 
                                        : handleLogin
                            }
                            className="auth-form"
                        >
                            {!isSetupFlow && (
                                <div className="form-field">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter your email"
                                        required
                                    />
                                </div>
                            )}

                            {(isSetupFlow || !isResetFlow || (isResetFlow && otpSent)) && (
                                <div className="form-field">
                                    <label htmlFor="password">
                                        {isSetupFlow || (isResetFlow && otpSent) ? 'New Password' : 'Password'}
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => validateAndSetPassword(e.target.value)}
                                        placeholder={isSetupFlow ? 'Enter new password' : 'Enter password'}
                                        required
                                    />
                                </div>
                            )}

                            {(isSetupFlow || (isResetFlow && otpSent)) && (
                                <div className="form-field">
                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                    <input
                                        id="confirmPassword"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        placeholder="Confirm your password"
                                        required
                                    />
                                </div>
                            )}

                            {isResetFlow && otpSent && (
                                <div className="form-field">
                                    <label htmlFor="otp">Verification Code</label>
                                    <input
                                        id="otp"
                                        type="text"
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        placeholder="Enter verification code"
                                        required
                                    />
                                </div>
                            )}

                            <button 
                                type="submit" 
                                disabled={loading}
                                style={{
                                    width: '100%',
                                    padding: '15px',
                                    marginTop: '20px',
                                    background: 'linear-gradient(135deg, #D8FF60 0%, #A8FF00 100%)',
                                    color: '#000000',
                                    border: '1px solid rgba(216, 255, 96, 0.3)',
                                    borderRadius: '8px',
                                    fontSize: '16px',
                                    fontWeight: '600',
                                    cursor: 'pointer',
                                    transition: 'all 0.3s ease',
                                    boxShadow: '0 4px 15px rgba(216, 255, 96, 0.2)',
                                    display: 'block',
                                    textAlign: 'center',
                                    lineHeight: '1.5',
                                    minHeight: '45px'
                                }}
                            >
                                {loading ? 'Loading...' : (
                                    isSetupFlow ? 'Set Password' :
                                    isResetFlow ? (otpSent ? 'Reset Password' : 'Send Code') :
                                    'Sign In'
                                )}
                            </button>
                        </form>

                        {!isSetupFlow && (
                            <div className="auth-links">
                                {!isResetFlow ? (
                                    <>
                                        <button 
                                            onClick={() => setIsResetFlow(true)} 
                                            className="text-button"
                                        >
                                            Forgot password?
                                        </button>
                                        <span className="separator">•</span>
                                        <a href="http://www.cleandata.me/pricing" className="text-button">
                                            Create account
                                        </a>
                                    </>
                                ) : (
                                    <button 
                                        onClick={() => setIsResetFlow(false)} 
                                        className="text-button"
                                    >
                                        Back to login
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                    
                    <div className="auth-image">
                        <img src={laptopImage} alt="Dashboard Preview" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;